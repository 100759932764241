/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {
  Grid,
  withStyles,
  Button,
  IconButton,
  Tabs,
  Tab,
  useTheme,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import withSnackbar from '../../../templates/WithSnackbar';

import CitizenTab from './components/CitizenTab';
import InfoTab from './components/InfoTab';

import styles from './styles';
import { AuthContext } from '../../../../providers/Auth';
import Resident from '../../../../model/entities/Resident';
import HomeService from '../../../../services/HomeService';
import { HomeEntity } from '../../../../interfaces/entities/home.entity';
import { useHistory, useParams } from 'react-router-dom';

type Props = { classes: any; login: Function; openSnackbar: any; auth: any; history: any };

function HomeInfo(props: Props) {
  const theme = useTheme();
  const { classes, openSnackbar } = props;
  const history = useHistory();
  const { imovelId } = useParams<{ imovelId: string }>();
  const { accessToken } = useContext(AuthContext);
  const [operator] = useState<string>();
  const [house, setHouse] = useState<HomeEntity>();
  const [valueTab, setValueTab] = useState<number>(0);
  const [citizens] = useState<Resident[]>();

  const goBack = () => {
    history.goBack();
  };

  async function getHome() {
    try {
      const result = await HomeService.getHome(Number(imovelId), accessToken);
      setHouse(result);
    } catch (error: any) {
      handleError(error.message);
    }
  }

  function handleError(e: any) {
    openSnackbar('error', e);
  }

  useEffect(() => {
    getHome();
  }, []);

  const handleEdit = () => {
    history.push(`/app/imoveis/${imovelId}/editar`);
  };

  const handleChangeTab = (event: any, newValue: any) => {
    setValueTab(newValue);
  };

  const renderPage = () => {
    if (!house) {
      return (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <Grid item xs={12} style={{ maxHeight: '100%' }}>
        <Grid item xs={12} className={classes.header}>
          <Grid item xs={1}>
            <IconButton aria-label="fechar" className={classes.closeButton} onClick={() => goBack()}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
            <img alt="House" src="/assets/house_color.png" className={classes.headerHouseImage} />
          </Grid>
          <Grid item xs className={classes.flexColumn}>
            <span className={classes.headerTitleBold}>
              {house?.street_name || 'Sem registro'}, {`N° ${house.street_number || 'Não informado'}`} -{' '}
              {house?.complement || 'Complemento não informado'}
            </span>
            <span className={classes.headerSubtitle}>
              {house?.district} - CEP: {house?.cep}
            </span>
            <span className={classes.headerSubtitleItalic}>{house?.street_type ? house?.street_type?.description : 'Sem registro'}</span>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: 20 }}>
            {true && <Button className={classes.btnEdit} variant="outlined" onClick={handleEdit}>
              Editar
            </Button>}
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.body}>
          <Tabs
            centered
            value={valueTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
          >
            <Tab className={classes.tab} label="Informações" />
            <Tab className={classes.tab} label="Cidadãos" />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={valueTab}
            onChangeIndex={handleChangeTab}
          >
            <TabPanel value={valueTab} index={0} dir={theme.direction}>
              <InfoTab
                house={house}
                operator={operator}
                // benefits={benefits}
                citizens={citizens}
                openSnackbar={openSnackbar}
              />
            </TabPanel>
            <TabPanel value={valueTab} index={1} dir={theme.direction}>
              <CitizenTab house={house} />
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.containerForm}>
      {renderPage()}
    </Grid>
  );
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default withStyles(styles)(withSnackbar(HomeInfo));
