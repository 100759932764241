/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Box,
  Button,
  Tooltip,
  IconButton,
  Typography,
  LinearProgress,
  Collapse,
} from '@material-ui/core';
import {
  ColDef,
  DataGrid,
  CellParams,
  GridOverlay,
} from '@material-ui/data-grid';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core/styles';
import { EditOutlined, AddCircle } from '@material-ui/icons';

import { ResidentHomeEntity } from '../../../../../../interfaces/entities/resident-home.entity';

import styles from './styles';
import AddIncomeDialog, { RefProps } from '../AddIncomeDialog';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface FamilyProps {
  classes: any;
  homeId: number;
  reloading: boolean;
  handleToggleRefresh(): void;
  family: ResidentHomeEntity[];
  benefitGrantedId?: string,
  reloadFamily(): Promise<void>;
}

interface Incomes {
  id: number;
  description: string;
  ammount: number;
  citizen_id: number;
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const FamilyTable: React.FC<FamilyProps> = (props) => {
  const {
    family,
    homeId,
    classes,
    reloading,
    handleToggleRefresh,
    benefitGrantedId,
    reloadFamily,
  } = props;
  const history = useHistory();
  const { url } = useRouteMatch();
  const dateFns = new DateFnsUtils();

  const [selectedIncomes, setSelectedIncomes] = useState<Incomes[]>([]);
  const [selectedResident, setSelectedResident] = useState<ResidentHomeEntity>();
  const [selectedIncome, setSelectedIncome] = useState<Incomes>();
  const addIncomeDialogRef = useRef<RefProps>(null);

  function navigateToCitizenEdit(citizenId: number) {
    let returnPath = url;

    if (benefitGrantedId) {
      returnPath += `?benefitGrantedId=${benefitGrantedId}`;
    }

    history.push(`/app/cidadao/${citizenId}/editar?houseId=${homeId}&returnPath=${returnPath}`);
  }

  function handleEditIncome(income: Incomes) {
    setSelectedIncome(income);
    addIncomeDialogRef.current?.toggleDialog();
  }

  const columnWidth = 170;
  const columns: ColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: columnWidth,
    },
    {
      field: 'birthdate',
      headerName: 'Data de nascimento',
      valueFormatter: ({ value }: CellParams) => !!value ? dateFns.format(new Date(value as string), 'dd/MM/yyyy') : 'Sem registro',
      width: columnWidth,
    },
    {
      field: 'birth_certificate',
      headerName: 'Certidão de Nascimento',
      width: columnWidth,
    },
    {
      field: 'responsible_kinship',
      headerName: 'Grau de Parentesco',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as any)?.description || 'Sem registro',
    },
    {
      field: 'telephone',
      headerName: 'Telefone',
      width: columnWidth,
    },
    {
      field: 'responsible',
      headerName: 'É responsável',
      valueFormatter: ({ value }: CellParams) => {
        return (value as boolean) ? 'Sim' : 'Não';
      },
      width: columnWidth - 10,
    },
    {
      field: 'bpc',
      headerName: 'BPC',
      valueFormatter: ({ value }: CellParams) => {
        return (value as boolean) ? 'Sim' : 'Não';
      },
      width: columnWidth - 70,
    },
    {
      field: 'total_income',
      headerName: 'Renda total',
      valueFormatter: ({ value }: CellParams) => {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }).format(value as number);
      },
      width: columnWidth,
    },
    {
      field: 'id',
      headerName: 'Editar',
      renderCell: ({ value }: CellParams) => (
        <Tooltip title="Editar cidadão">
          <IconButton onClick={() => navigateToCitizenEdit(value as number)}>
            <EditOutlined />
          </IconButton>
        </Tooltip>
      ),
      width: columnWidth - 50,
    },
  ];

  const incomesColumns: ColDef[] = [
    {
      field: 'description',
      headerName: 'Origem da renda',
      width: columnWidth + 20,
    },
    {
      field: 'ammount',
      headerName: 'Valor da renda',
      width: columnWidth + 20,
      valueFormatter: ({ value }: CellParams) => {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }).format(value as number);
      },
    },
    {
      field: 'id',
      headerName: 'Editar',
      width: columnWidth + 20,
      renderCell: ({ row }: CellParams) => (
        <Tooltip title="Editar renda">
          <IconButton onClick={() => handleEditIncome(row as Incomes)}>
            <EditOutlined />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    realodIncomes();
  }, [family]);

  const realodIncomes = useCallback(() => {
    if (selectedIncomes.length && selectedResident) {
      const newIncomes = family.find((resident) => resident.id === selectedResident.id)?.incomes;
      if (newIncomes) {
        setSelectedIncomes(newIncomes);
      }
    }
  }, [family]);

  function renderVisibilityIcon(incomes: Incomes[], resident: ResidentHomeEntity) {
    if (incomes.length === 0) {
      return (
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setSelectedResident(resident);
            addIncomeDialogRef.current?.toggleDialog();
          }}
        >
          Adicionar renda
        </Button>
      );
    }
    if (incomes.length && selectedIncomes.length) {
      if (selectedIncomes[0].citizen_id === incomes[0].citizen_id) {
        return (
          <Button color="primary" variant="outlined" onClick={() => handleToggleIncomes([])}>
            Gerenciar renda
          </Button>
        );
      }
    }

    return (
      <Button color="primary" variant="outlined" onClick={() => handleToggleIncomes(incomes, resident)}>
        Gerenciar renda
      </Button>
    );
  }

  function handleToggleIncomes(incomes: Incomes[], resident?: ResidentHomeEntity) {
    setSelectedIncomes(incomes);
    setSelectedResident(resident);
  }

  function handleAddIncome() {
    addIncomeDialogRef.current?.toggleDialog();
  }

  return (
    <>
      <Box>
        <Box className={classes.tableContainer}>
          <DataGrid
            disableSelectionOnClick
            columns={columns}
            loading={reloading}
            rows={family}
            autoHeight
            localeText={{
              footerTotalRows: 'de',
              footerPaginationRowsPerPage: 'Residentes por página'
            }}
            components={{
              LoadingOverlay: CustomLoadingOverlay,
            }}
          />
        </Box>
        <Collapse in={!!selectedIncomes?.length}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              className={classes.incomesContainer}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">Renda do cidadão</Typography>
                <Button color="primary" endIcon={<AddCircle />} variant="outlined" onClick={handleAddIncome}>
                  Adicionar
                </Button>
              </Box>
              <DataGrid
                className={classes.incomesHeight}
                disableSelectionOnClick
                columns={incomesColumns}
                rows={selectedIncomes}
                loading={reloading}
                autoHeight
                localeText={{
                  footerTotalRows: 'de',
                  footerPaginationRowsPerPage: 'Rendas por página'
                }}
                components={{
                  LoadingOverlay: CustomLoadingOverlay,
                }}
              />
            </Box>
          </Box>
        </Collapse>
      </Box>
      <AddIncomeDialog
        ref={addIncomeDialogRef}
        citizenId={selectedResident?.id || 0}
        reloadMembers={handleToggleRefresh}
        selectedIncome={selectedIncome}
        onSuccess={() => {
          handleToggleRefresh();
          reloadFamily();
        }}
      />
    </>
  );
}

export default withStyles(styles)(FamilyTable);
