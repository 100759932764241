import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import withSnackbar from '../../../templates/WithSnackbar';

import BenefitListPas from './BenefitListPas';


import styles from './styles';
import { useHistory } from 'react-router-dom';
import BenefitListBread from './BenefitListBread';
import BenefitListFish from './BenefitListFish';

type Props = { classes: any; openSnackbar: any; benefit: number; };

const BenefitList: React.FC<Props> = ({ benefit }) => {
  const history = useHistory();

  const renderBenefitList = () => {
    if (benefit === 1) {
      return (
        <BenefitListPas history={history} enableRegister={false} />
      );
    } else if (benefit === 2) {
      return (
        <BenefitListFish history={history} enableRegister={true} />
      );
    } else if (benefit === 3) {
      return (
        <BenefitListBread history={history} enableRegister={true} />
      );
    } 

    return null;
  };

  return <>{renderBenefitList()}</>;
}

export default withStyles(styles)(withSnackbar(BenefitList));
