const styles: any = {
    mainGrid: {
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/assets/bg_login.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    divCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    divButton: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 15,
        marginBottom: 10,
    },
    cardLogin: {
        backgroundColor: '#FFFFFF',
        borderRadius: 30,
        padding: 30,
        justifyContent: 'center',
        boxShadow: '-2px 0 12px #00000029'
    },
    logoSfc: {
        maxWidth: '200px',
        width: '100%',
        marginBottom: 15,
    },
    logoAcelera: {
        maxWidth: '100px',
        width: '100%',
        marginTop: 10,
    },
    linkForgot: {
        color: '#707070',
        fontSize: 14,
        fontWeight: '500',
        border: 'none',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    viewModalBackground: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        top: 0,
    },
    viewBackground: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: '#0e0e0e80',
    },
    viewModal: {
        zIndex: 3,
        backgroundColor: '#FFFFFF',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 40,
        borderRadius: 20,
        margin: '0 70px',
    },
    titleModal: {
        fontSize: 20,
        color: '#0A4F9D',
        fontWeight: 'bold'
    },
    viewRow: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    buttonModal: {
        backgroundColor: '#0A4F9D',
        width: '48%',
        height: 50
    },
    buttonCancelModal: {
        backgroundColor: '#707070',
        width: '48%',
        height: 50
    },
    formContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    formForgotContainer: {
        margin: '30px 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: '20px',
    }
};

export default styles;