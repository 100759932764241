const styles: any = {
    containerForm: {
        padding: 50,
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center',
    },
    subTitle: {
        fontSize: "14px",
        fontWeight: 300,
        fontFamily: 'Roboto',
    },
    button: {
        fontSize: '14px',
        fontWeight: '400',
    },
    buttonClean: {
        borderColor: '#878787',
        color: '#878787',
        "&:hover": {
            backgroundColor: '#878787',
            color: '#fff',
        },
    },
    buttonCancel: {
        marginLeft: '15px',
        borderColor: '#D4586D',
        color: '#D4586D',
        "&:hover": {
            backgroundColor: '#D4586D',
            color: '#fff',
        },
    },
    buttonRegister: {
        marginLeft: '15px',
        backgroundColor: '#004692',
        color: '#fff',
        "&:hover": {
            backgroundColor: '#408BCC'
        }
    },
    select: {
        width: '100%',
    },
    divCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    pageTitle: {
        color: '#0A0A0A59',
        fontWeight: 'bold',
        marginRight: '15px',
    },
    pageLine: {
        backgroundColor: '#0A0A0A59',
        height: 1,
        marginRight: '15px',
    },
    btnFilter: {
        color: '#177AFC',
        borderColor: '#177AFC',
        margin: '0 10px',
    },
    btnAdd: {
        backgroundColor: '#0A4F9D',
        color: '#FFFFFF',
        margin: '0 10px',
    },
    popper: {
        position: 'absolute !important',
        left: '10px !important',
        zIndex: 3,
        top: 'unset !important',
    },
    label: {
        color: '#707070',
        fontWeight: 'bold',
    },
    benefitTitle: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#707070',
    },
    benefitTitleContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    gridCircularProgress: {
        display: 'flex',
        height: '80vh',
        justifyContent: 'center',
        alignItems: 'center'
    },
    circularProgress: {
        color: '#0A4F9D'
    }
};

export default styles;