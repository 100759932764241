import { useEffect, useRef } from 'react'

import { useField } from '@unform/core'
import { Switch } from '@material-ui/core';

interface MySwitchProps {
  name: string;
  label?: boolean;
  inputProps?: object;
  onChange?: (event: any) => void;
  initialValue?: any;
  disabled?: boolean;
}

export default function MySwitch({ name, inputProps, onChange, initialValue, disabled }: MySwitchProps) {
  const switchRef = useRef(null);
  const { fieldName, defaultValue = initialValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: switchRef,
      getValue: ref => {
        return ref.current.checked
      },
      clearValue: ref => {
        ref.current.checked = false
      },
      setValue: (ref, checked) => {
        ref.current.checked = checked
      },
    })
  }, [defaultValue, fieldName, registerField])

  return (
    <Switch
      onChange={onChange}
      inputRef={switchRef}
      disabled={disabled}
      name={name}
      inputProps={inputProps}
      defaultChecked={defaultValue}
      defaultValue={defaultValue}
      color="primary"
    />
  )
}