/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Grid, withStyles, Box, Typography, CircularProgress, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';

import withSnackbar from '../../../templates/WithSnackbar';

import Header from './components/Header';
import HomeTab from './components/HomeTab';
import BenefitTab from './components/BenefitTab';
import CitizenTab from './components/CitizenTab';

import { TabType } from '../../../../utils/enum';

import styles from './styles';
import { AuthContext } from '../../../../providers/Auth';
import homeService from '../../../../services/HomeService';
import CitizenService from '../../../../services/CitizenService';
import BenefitFishService from '../../../../services/BenefitFishService';
import BenefitPasService from '../../../../services/BenefitPasService';
import BenefitBreadTableService from '../../../../services/BenefitBreadTableService';

type Props = {
  auth: any,
  history: any,
  classes: any,
  login: Function,
  openSnackbar: any,
};

interface HomePanelData {
  totalHomes: number,
  totalHomesImcomplete: number,
  totalHomesByDistrict: { labels: string[], data: number[] },
  totalHomesByGarbageDisposal: { labels: string[], data: number[] },
  totalHomesByIncome: { labels: string[], data: number[] },
  totalHomesBySewage: { labels: string[], data: number[] },
  totalHomesByPowerSupply: { labels: string[], data: number[] },
  totalHomesByWaterSupply: { labels: string[], data: number[] },
}
interface CitizenPanelData {
  totalCitizens: number,
  totalCitizensIncomplete: number,
  totalCitizensByRace: { labels: string[], data: number[] },
  totalCitizensByGender: { labels: string[], data: number[] },
  totalCitizensByReligion: { labels: string[], data: number[] },
  totalCitizensBySchooling: { labels: string[], data: number[] },
  totalCitizensByJobSituation: { labels: string[], data: number[] },
  totalCitizensByAge: { labels: string[], data: number[] },
  totalCitizensByDeficiency: { labels: string[], data: number[] },
}

interface PasPanelData {
  totalBenefitiaryIncomplete: number,
  totalBenefitiaryRegistred: number,
  totalBenefitiaryByYear: { labels: string[], data: number[] },
  totalBenefitiaryByStatus: { labels: string[], data: number[] },
  totalBenefitiaryByGender: { labels: string[], data: number[] },
  totalBenefitiaryByComplexion: { labels: string[], data: number[] },
}

interface PeixePanelData {
  totalBenefitiaryIncomplete: number,
  totalBenefitiaryRegistred: number,
  totalBenefitiaryByYear: { labels: string[], data: number[] },
  totalBenefitiaryByStatus: {
    labels: string[],
    datasets: {
      data: number[],
      label: string
    }[]
  },
  totalBenefitiaryByGender: { labels: string[], data: number[] },
  totalBenefitiaryByComplexion: { labels: string[], data: number[] },
}

interface BreadPanelData {
  totalBenefitiaryIncomplete: number,
  totalBenefitiaryRegistred: number,
  totalBenefitiaryByYear: { labels: string[], data: number[] },
  totalBenefitiaryByStatus: { labels: string[], data: number[] },
  totalBenefitiaryByGender: { labels: string[], data: number[] },
  totalBenefitiaryByComplexion: { labels: string[], data: number[] },
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Panels: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;

  const theme = useTheme();
  const { accessToken } = useContext(AuthContext);

  const [tab, setTab] = useState<number>(TabType.Domicilios);

  const [isLoadingHomeData, setIsLoadingHomeData] = useState(false);
  const [isLoadingCitizensData, setIsLoadingCitizensData] = useState(false);
  const [isLoadingBenefitData, setIsLoadingBenefitData] = useState(false);
  const [isLoadedCitizensData, setIsLoadedCitizensData] = useState(false);
  const [isLoadedHomeData, setIsLoadedHomeData] = useState(false);
  const [isLoadedBenefitData, setIsLoadedBenefitData] = useState(false);
  const [homePanelData, setHomePanelData] = useState<HomePanelData>({
    totalHomes: 0,
    totalHomesImcomplete: 0,
    totalHomesByDistrict: { labels: [], data: [] },
    totalHomesByGarbageDisposal: { labels: [], data: [] },
    totalHomesByIncome: { labels: [], data: [] },
    totalHomesBySewage: { labels: [], data: [] },
    totalHomesByPowerSupply: { labels: [], data: [] },
    totalHomesByWaterSupply: { labels: [], data: [] },
  });
  const [citizenPanelData, setCitizenPanelData] = useState<CitizenPanelData>({
    totalCitizens: 0,
    totalCitizensIncomplete: 0,
    totalCitizensByRace: { labels: [], data: [] },
    totalCitizensByGender: { labels: [], data: [] },
    totalCitizensByReligion: { labels: [], data: [] },
    totalCitizensBySchooling: { labels: [], data: [] },
    totalCitizensByJobSituation: { labels: [], data: [] },
    totalCitizensByAge: { labels: [], data: [] },
    totalCitizensByDeficiency: { labels: [], data: [] },
  });
  const [pasPanelData, setPasPanelData] = useState<PasPanelData>({
    totalBenefitiaryIncomplete: 0,
    totalBenefitiaryRegistred: 0,
    totalBenefitiaryByYear: { labels: [], data: [] },
    totalBenefitiaryByStatus: { labels: [], data: [] },
    totalBenefitiaryByGender: { labels: [], data: [] },
    totalBenefitiaryByComplexion: { labels: [], data: [] },
  });
  const [peixePanelData, setPeixePanelData] = useState<PeixePanelData>({
    totalBenefitiaryIncomplete: 0,
    totalBenefitiaryRegistred: 0,
    totalBenefitiaryByYear: { labels: [], data: [] },
    totalBenefitiaryByStatus: {
      labels: [],
      datasets: [],
    },
    totalBenefitiaryByGender: { labels: [], data: [] },
    totalBenefitiaryByComplexion: { labels: [], data: [] },
  });
  const [breadPanelData, setBreadPanelData] = useState<BreadPanelData>({
    totalBenefitiaryIncomplete: 0,
    totalBenefitiaryRegistred: 0,
    totalBenefitiaryByYear: { labels: [], data: [] },
    totalBenefitiaryByStatus: { labels: [], data: [] },
    totalBenefitiaryByGender: { labels: [], data: [] },
    totalBenefitiaryByComplexion: { labels: [], data: [] },
  });

  const loadHomePanelData = useCallback(async function () {
    try {
      if (isLoadedHomeData) {
        return;
      }
      setIsLoadingHomeData(true);
      const { total: totalHomes } = await homeService.getTotalHomes(accessToken);
      const { total: totalHomesImcomplete } = await homeService.getTotalHomesImcomple(accessToken);
      const totalHomesByDistrict = await homeService.getTotalHomesByDistrict(accessToken);
      const totalHomesByGarbageDisposal = await homeService.getTotalHomesByGarbageDisposal(accessToken);
      const totalHomesByIncome = await homeService.getTotalHomesByIncome(accessToken);
      const totalHomesByPowerSupply = await homeService.getTotalHomesByPowerSupply(accessToken);
      const totalHomesByWaterSupply = await homeService.getTotalHomesByWaterSupply(accessToken);
      const totalHomesBySewage = await homeService.getTotalHomesBySewage(accessToken);
      setIsLoadedHomeData(true);

      setHomePanelData((prevState) => ({
        ...prevState,
        totalHomes,
        totalHomesImcomplete,
        totalHomesByDistrict,
        totalHomesByGarbageDisposal,
        totalHomesByIncome,
        totalHomesByWaterSupply,
        totalHomesBySewage,
        totalHomesByPowerSupply,
      }));
    } catch (error: any) {
      openSnackbar('error', error?.message);
    } finally {
      setIsLoadingHomeData(false);
    }
  }, [tab]);

  const loadCitizenPanelData = useCallback(async function () {
    try {
      if (isLoadedCitizensData) {
        return;
      }

      setIsLoadingCitizensData(true);
      const { total: totalCitizens } = await CitizenService.getTotalCitizens(accessToken);
      const { total: totalCitizensIncomplete } = await CitizenService.getTotalCitizensImcomplete(accessToken);
      const totalCitizensByRace = await CitizenService.getTotalCitizensByRace(accessToken);
      const totalCitizensByDeficiency = await CitizenService.getTotalCitizensByDeficiency(accessToken);
      const totalCitizensByGender = await CitizenService.getTotalCitizensByGender(accessToken);
      const totalCitizensByAge = await CitizenService.getTotalCitizensByAge(accessToken);
      const totalCitizensByJobSituation = await CitizenService.getTotalCitizensByJobSituation(accessToken);
      const totalCitizensByReligion = await CitizenService.getTotalCitizensByReligion(accessToken);
      const totalCitizensBySchooling = await CitizenService.getTotalCitizensBySchooling(accessToken);
      setIsLoadedCitizensData(true);

      setCitizenPanelData((prevState) => ({
        ...prevState,
        totalCitizens,
        totalCitizensIncomplete,
        totalCitizensByRace,
        totalCitizensByGender,
        totalCitizensByReligion,
        totalCitizensBySchooling,
        totalCitizensByJobSituation,
        totalCitizensByAge,
        totalCitizensByDeficiency,
      }));
    } catch (error: any) {
      openSnackbar('error', error?.message);
    } finally {
      setIsLoadingCitizensData(false);
    }
  }, [tab])

  const loadPasBenefitPanelData = useCallback(async function () {
    try {
      if (isLoadedBenefitData) {
        return;
      }

      setIsLoadingBenefitData(true);
      const { total: totalBenefitiaryRegistred } = await BenefitPasService.getTotalBenefitiaryRegistered(accessToken);
      const { total: totalBenefitiaryIncomplete } = await BenefitPasService.getTotalBenefitiaryIncomplete(accessToken);
      const totalBenefitiaryByYear = await BenefitPasService.getTotalBenefitiaryByYear(accessToken);
      const totalBenefitiaryByStatus = await BenefitPasService.getTotalBeneficiaryByStatus(accessToken);
      const totalBenefitiaryByGender = await BenefitPasService.getTotalBenefitiaryByGender(accessToken);
      const totalBenefitiaryByComplexion = await BenefitPasService.getTotalBenefitiaryByComplexion(accessToken);
      setIsLoadedBenefitData(true);

      setPasPanelData((prevState) => ({
        ...prevState,
        totalBenefitiaryByYear,
        totalBenefitiaryByStatus,
        totalBenefitiaryByGender,
        totalBenefitiaryRegistred,
        totalBenefitiaryIncomplete,
        totalBenefitiaryByComplexion,
      }));
    } catch (error: any) {
      openSnackbar('error', error?.message);
    } finally {
      setIsLoadingBenefitData(false);
    }
  }, [tab]);

  const loadPeixeBenefitPanelData = useCallback(async function () {
    try {
      if (isLoadedBenefitData) {
        return;
      }

      setIsLoadingBenefitData(true);
      const { total: totalBenefitiaryRegistred } = await BenefitFishService.getTotalBenefitiaryRegistered(accessToken);
      const { total: totalBenefitiaryIncomplete } = await BenefitFishService.getTotalConcessions(accessToken);
      const totalBenefitiaryByYear = await BenefitFishService.getTotalBenefitiaryByYear(accessToken);
      const totalBenefitiaryByStatus = await BenefitFishService.getTotalBeneficiaryByStatus(accessToken);
      const totalBenefitiaryByGender = await BenefitFishService.getTotalBenefitiaryByGender(accessToken);
      const totalBenefitiaryByComplexion = await BenefitFishService.getTotalBenefitiaryByComplexion(accessToken);
      setIsLoadedBenefitData(true);

      setPeixePanelData((prevState) => ({
        ...prevState,
        totalBenefitiaryByYear,
        totalBenefitiaryByStatus,
        totalBenefitiaryByGender,
        totalBenefitiaryRegistred,
        totalBenefitiaryIncomplete,
        totalBenefitiaryByComplexion,
      }));
    } catch (error: any) {
      openSnackbar('error', error?.message);
    } finally {
      setIsLoadingBenefitData(false);
    }
  }, [tab]);

  const loadBreadBenefitPanelData = useCallback(async function () {
    try {
      if (isLoadedBenefitData) {
        return;
      }

      setIsLoadingBenefitData(true);
      const { total: totalBenefitiaryRegistred } = await BenefitBreadTableService.getTotalBenefitiaryRegistered(accessToken);
      const { total: totalBenefitiaryIncomplete } = await BenefitBreadTableService.getTotalBenefitiaryIncomplete(accessToken);
      const totalBenefitiaryByYear = await BenefitBreadTableService.getTotalBenefitiaryByYear(accessToken);
      const totalBenefitiaryByStatus = await BenefitBreadTableService.getTotalBeneficiaryByStatus(accessToken);
      const totalBenefitiaryByGender = await BenefitBreadTableService.getTotalBenefitiaryByGender(accessToken);
      const totalBenefitiaryByComplexion = await BenefitBreadTableService.getTotalBenefitiaryByComplexion(accessToken);
      setIsLoadedBenefitData(true);

      setBreadPanelData((prevState) => ({
        ...prevState,
        totalBenefitiaryByYear,
        totalBenefitiaryByStatus,
        totalBenefitiaryByGender,
        totalBenefitiaryRegistred,
        totalBenefitiaryIncomplete,
        totalBenefitiaryByComplexion,
      }));
    } catch (error: any) {
      openSnackbar('error', error?.message);
    } finally {
      setIsLoadingBenefitData(false);
    }
  }, [tab]);

  useEffect(() => {
    switch (tab) {
      case TabType.Domicilios:
        loadHomePanelData();
        break;
      case TabType.Individuos:
        loadCitizenPanelData();
        break;
      case TabType.Beneficios:
        loadBreadBenefitPanelData();
        loadPasBenefitPanelData();
        loadPeixeBenefitPanelData();
        break;
      default:
        break;
    }
  }, [
    loadHomePanelData,
    loadCitizenPanelData,
    loadPasBenefitPanelData,
    loadPeixeBenefitPanelData,
    loadBreadBenefitPanelData
  ]
  );

  function onChangeTab(value: number) {
    setTab(value);
  }

  const renderLoad = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.circularProgress}
      >
        <CircularProgress disableShrink />
      </Box>
    );
  }

  const renderHomeTab = () => {
    if (isLoadingHomeData) return renderLoad();
    return (
      <HomeTab
        totalHomes={homePanelData.totalHomes}
        totalHomesImcomple={homePanelData.totalHomesImcomplete}
        totalHomesByDistrict={homePanelData.totalHomesByDistrict}
        totalHomesByGarbageDisposal={homePanelData.totalHomesByGarbageDisposal}
        totalHomesByIncome={homePanelData.totalHomesByIncome}
        totalHomesBySewage={homePanelData.totalHomesBySewage}
        totalHomesByPowerSupply={homePanelData.totalHomesByPowerSupply}
        totalHomesByWaterSupply={homePanelData.totalHomesByWaterSupply}
      />
    );
  }

  const renderCitizenTab = () => {
    if (isLoadingCitizensData) return renderLoad();
    return <CitizenTab
      totalCitizens={citizenPanelData.totalCitizens}
      totalCitizensIncomple={citizenPanelData.totalCitizensIncomplete}
      totalCitizensByRace={citizenPanelData.totalCitizensByRace}
      totalCitizensByGender={citizenPanelData.totalCitizensByGender}
      totalCitizensByReligion={citizenPanelData.totalCitizensByReligion}
      totalCitizensBySchooling={citizenPanelData.totalCitizensBySchooling}
      totalCitizensByJobSituation={citizenPanelData.totalCitizensByJobSituation}
      totalCitizensByAge={citizenPanelData.totalCitizensByAge}
      totalCitizensByDeficiency={citizenPanelData.totalCitizensByDeficiency}
    />
  }

  const renderBenefitTab = () => {
    if (isLoadingBenefitData) return renderLoad();
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" color="textSecondary">Pão na Mesa</Typography>
          </Grid>
          <Grid item xs={12}>
            <BenefitTab
              benefitId={3}
              totalRegistred={breadPanelData.totalBenefitiaryRegistred}
              totalIncomplete={breadPanelData.totalBenefitiaryIncomplete}
              totalBenefitiaryByYear={breadPanelData.totalBenefitiaryByYear}
              totalBenefitiaryByStatus={breadPanelData.totalBenefitiaryByStatus}
              totalBenefitiaryByGender={breadPanelData.totalBenefitiaryByGender}
              totalBenefitiaryByComplexion={breadPanelData.totalBenefitiaryByComplexion}
            />
          </Grid>
        </Grid>
        <Divider variant="fullWidth" className={classes.dividerBenefits} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" color="textSecondary">Programa de Assistência Social (PAS)</Typography>
          </Grid>
          <Grid item xs={12}>
            <BenefitTab
              benefitId={1}
              totalRegistred={pasPanelData.totalBenefitiaryRegistred}
              totalIncomplete={pasPanelData.totalBenefitiaryIncomplete}
              totalBenefitiaryByYear={pasPanelData.totalBenefitiaryByYear}
              totalBenefitiaryByStatus={pasPanelData.totalBenefitiaryByStatus}
              totalBenefitiaryByGender={pasPanelData.totalBenefitiaryByGender}
              totalBenefitiaryByComplexion={pasPanelData.totalBenefitiaryByComplexion}
            />
          </Grid>
        </Grid>
        <Divider variant="fullWidth" className={classes.dividerBenefits} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" color="textSecondary">Vale Semana Santa (PEIXE)</Typography>
          </Grid>
          <Grid item xs={12}>
            <BenefitTab
              benefitId={2}
              totalRegistred={peixePanelData.totalBenefitiaryRegistred}
              totalIncomplete={peixePanelData.totalBenefitiaryIncomplete}
              totalBenefitiaryByYear={peixePanelData.totalBenefitiaryByYear}
              totalBenefitiaryByStatus={peixePanelData.totalBenefitiaryByStatus}
              totalBenefitiaryByGender={peixePanelData.totalBenefitiaryByGender}
              totalBenefitiaryByComplexion={peixePanelData.totalBenefitiaryByComplexion}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Grid container className={classes.containerForm}>
      <Grid item xs={12} className={classes.divCenterText}>
        <Header onChange={onChangeTab} />
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tab}
          onChangeIndex={onChangeTab}
        >
          <TabPanel value={tab} index={TabType.Domicilios} dir={theme.direction}>
            {renderHomeTab()}
          </TabPanel>
          <TabPanel value={tab} index={TabType.Individuos} dir={theme.direction}>
            {renderCitizenTab()}
          </TabPanel>
          <TabPanel value={tab} index={TabType.Beneficios} dir={theme.direction}>
            {renderBenefitTab()}
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(withSnackbar(Panels));