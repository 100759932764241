const styles: any = {
  containerForm: {
    padding: 30,
    justifyContent: 'center',
    backgroundColor: '#EEEEEE',
    border: '1px solid #0A0A0A59',
    borderRadius: 10,
  },
  buttonRegister: {
    marginLeft: '15px',
    color: '#fff',
    textTransform: 'capitalize',
    width: '100%',
  },
  divCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  select: {
    width: '100%',
  },
  label: {
    color: '#707070',
    fontWeight: 'bold',
  },
  infoTitle: {
    color: '#0A0A0A59',
    fontWeight: '500'
  },
  ocurrenceTable: {
    width: '100%',
    '& div.MuiDataGrid-root.MuiDataGrid-root': {
      backgroundColor: '#fff',
    }
  },
};

export default styles;