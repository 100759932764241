const styles: any = {
    containerForm: {
        padding: 50,
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center',
    },
    subTitle: {
        fontSize: "14px",
        fontWeight: 300,
        fontFamily: 'Roboto',
    },
    button: {
        fontSize: '14px',
        fontWeight: '400',
    },
    buttonClean: {
        borderColor: '#878787',
        color: '#878787',
        "&:hover": {
            backgroundColor: '#878787',
            color: '#fff',
        },
    },
    buttonCancel: {
        marginLeft: '15px',
        borderColor: '#D4586D',
        color: '#D4586D',
        "&:hover": {
            backgroundColor: '#D4586D',
            color: '#fff',
        },
    },
    buttonRegister: {
        marginLeft: '15px',
        backgroundColor: '#004692',
        color: '#fff',
        "&:hover": {
            backgroundColor: '#408BCC'
        }
    },
    select: {
        width: '100%',
    },
    divCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    pageTitle: {
        color: '#0A0A0A59',
        fontWeight: 'bold',
        marginRight: '10px',
    },
    pageLine: {
        backgroundColor: '#0A0A0A59',
        flex: 1,
        height: 1,
    },
    btnFilter: {
        color: '#177AFC',
        borderColor: '#177AFC',
        margin: '0 10px',
    },
    btnAdd: {
        backgroundColor: '#0A4F9D',
        color: '#FFFFFF',
        margin: '0 10px',
    },
    containerFormStreet: {
        padding: 30,
        justifyContent: 'flex-start',
        backgroundColor: '#EEEEEE',
        border: '1px solid #0A0A0A59',
        borderRadius: 10,
    },
    containerFormRegisterRegisterHome: {
        padding: 30,
        backgroundColor: '#EEEEEE',
        border: '1px solid #0A0A0A59',
        borderRadius: 10,
    },
    subTitleRegisterHome: {
        fontSize: "14px",
        fontWeight: 300,
        fontFamily: 'Roboto',
    },
    buttonRegisterHome: {
        fontSize: '14px',
        fontWeight: '400',
    },
    buttonCleanRegisterHome: {
        borderColor: '#878787',
        color: '#878787',
        "&:hover": {
            backgroundColor: '#878787',
            color: '#fff',
        },
    },
    buttonMap: {
        borderColor: '#D4586D',
        color: '#707070',
        textTransform: 'capitalize',
        "&:hover": {
            backgroundColor: '#D4586D',
            color: '#fff',
        },
        width: '100%',
    },
    buttonCancelRegisterHome: {
        marginLeft: '15px',
        borderColor: '#D4586D',
        color: '#707070',
        textTransform: 'capitalize',
        "&:hover": {
            backgroundColor: '#D4586D',
            color: '#fff',
        },
        width: '200px',
    },
    buttonRegisterHouse: {
        marginLeft: '15px',
        backgroundColor: '#004692',
        color: '#fff',
        textTransform: 'capitalize',
        "&:hover": {
            backgroundColor: '#408BCC'
        },
        width: '200px',
    },
    selectRegisterHome: {
        width: '100%',
    },
    divCenterRegisterHome: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    label: {
        color: '#707070',
        fontWeight: 'bold'
    },
    labelTitle: {
        color: '#707070',
        fontWeight: 'bold',
        fontSize: 14,
        marginBottom: '8px',
    },
};

export default styles;