type Neighborhood = { name: string }

const neighborhoods: Array<Neighborhood> = [
  { name: "BAIXA FRIA" },
  { name: "CAIPE" },
  { name: "CAIPE DE BAIXO" },
  { name: "CAIPE DE CIMA" },
  { name: "CAJAIBA" },
  { name: "CALMONTE" },
  { name: "CAMPINAS" },
  { name: "CENTRO" },
  { name: "CENTRO (DORNA)" },
  { name: "COROADO" },
  { name: "CURUGE" },
  { name: "CURUPEBA" },
  { name: "DOM JOAO" },
  { name: "DRENA" },
  { name: "ENGENHO DAGUA" },
  { name: "ENGENHO DE BAIXO" },
  { name: "ESTRADA DE CAMPINAS" },
  { name: "FAZENDA MACACO" },
  { name: "FERROLHO" },
  { name: "GERAL" },
  { name: "GURUJÉ" },
  { name: "HORIZON" },
  { name: "ILHA DAS FONTES" },
  { name: "ILHA DO PATY" },
  { name: "JABEQUARA" },
  { name: "JABEQUARA DA AREIA" },
  { name: "JABEQUARA DAS FLORES" },
  { name: "MACACO" },
  { name: "MADRUGA" },
  { name: "MONTE" },
  { name: "MONTE DE CIMA" },
  { name: "MONTE RECONCAVO" },
  { name: "MURIBECA" },
  { name: "NITEROI" },
  { name: "NOVA SAO FRANCISCO" },
  { name: "PARAMIRIM" },
  { name: "PITANGUEIRA" },
  { name: "RESGATE" },
  { name: "ROSEIRA" },
  { name: "SANTA ELISA" },
  { name: "SANTA RITA" },
  { name: "SANTO ESTEVAO" },
  { name: "SAO BENTO" },
  { name: "SÃO BENTO (BABILONIA)" },
  { name: "SÃO BENTO (DRENA 1)" },
  { name: "SÃO BENTO (DRENA 2)" },
  { name: "SOCORRO" },
  { name: "VENCIMENTO" },
];

export default neighborhoods;
