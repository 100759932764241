const styles: any = {
    container: {
        backgroundColor: '#EEEEEE',
        height: '100vh',
        overflowY: 'hidden',
    },
    containerMenu: {
        // width: '110px',
        height: '100%',
    },
    containerApp: {
        height: '100vh',
        flex: 1,
        backgroundColor: '#F9F9F9',
        borderRadius: '30px 0 0 30px',
        boxShadow: '-2px 0 12px #00000029',
        overflowY: 'auto',
    },
    logoSfc: {
        width: '65px',
        height: '65px',
        marginBottom: 20,
    },
    logoAcelera: {
        width: '60%',
        marginBottom: 20,
    },
    divCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    linkButton: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '5px 0',
        width: '100%',
        borderRadius: '10px',
        padding: '8px 0',
        textDecoration: 'none',
    },
    linkButtonUnderline: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '5px 0',
        width: '100%',
        borderRadius: '10px',
        padding: '8px 0',
    },
    linkIcon: {
        height: '30px',
        width: '100%',
        color: '#707070',
    },
    linkTitle: {
        fontSize: 13,
        textDecoration: 'none',
        color: '#707070',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    linkButtonActive: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '5px 0',
        width: '100%',
        borderRadius: '10px',
        padding: '8px 0',
        textDecoration: 'none',
        backgroundImage: `linear-gradient(45deg, #177AFC , #0C3D7E)`,
        boxShadow: '-2px 0 12px #00000029',
    },
    linkIconActive: {
        height: '30px',
        width: '100%',
        color: '#FFFFFF',
    },
    linkTitleActive: {
        fontSize: 13,
        textDecoration: 'none',
        color: '#FFFFFF',
        fontWeight: 'bold',
    },
    divisor: {
        backgroundColor: '#70707080',
        height: 2,
        width: '80%',
        margin: '10px 0',
    },
    sideLogoContainer: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#EEE',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'fit-content',
    },
    sideContainer: {
        height: '100%',
        padding: '30px 15px',
    },
    sideLogoutContainer: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#EEE',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    menuItemsContainer: {
        overflowY: 'auto',
        height: '100%',
        // backgroundColor: '#000',
        '@media screen and (max-height: 680px)': {
            height: '75%',
            padding: '0px 0px 35px 0px',
        }
    },
};

export default styles;