const styles: any = {
  filterDiv: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #0A0A0A59',
    borderRadius: 10,
    padding: 20,
    fontSize: 14,
    flex: 1,
    margin: 'auto',
    width: "100%",
  },
  filterTitle: {
    color: '#0A0A0A59',
    fontWeight: 'bold',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  filterContainer: {
    width: '100%',
  },
  buttonRegister: {
    display: 'flex',
    color: '#fff',
    textTransform: 'capitalize',
  },
  reportHeight: {
    // height: '25vh',
  },
  reportContainer: {
    marginTop: '1rem',
  },
  tableToolBar: {
    width: '100%',
    marginBottom: '0.5rem',
  },
};

export default styles;
