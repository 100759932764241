/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import {
  Code,
  LooksOne,
  LooksTwo,
  FormatBold,
  FormatQuote,
  FormatItalic,
  FormatUnderlined,
  FormatListNumbered,
  FormatListBulleted,
} from '@material-ui/icons';
import isHotkey from 'is-hotkey';
import { useLocation } from 'react-router-dom';
import { Grid, withStyles, Button } from '@material-ui/core';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { Editor, Transforms, createEditor, Element as SlateElement } from 'slate';

import { AuthContext } from '../../../../../../providers/Auth';
import BenefitService from '../../../../../../services/BenefitService';
import { CitizenBenefitEntity } from '../../../../../../interfaces/entities/citizen-benefit.entity';

import styles from './styles';

type Props = {
  classes: any,
};

const HOTKEYS: any = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const ObservationField: React.FC<Props> = (props) => {
  const { classes } = props;
  const { accessToken } = useContext(AuthContext);

  const [benefitGranted, setBenefitGranted] = useState<CitizenBenefitEntity>();

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);
  const [value, setValue] = useState<any[]>([
    {
      type: 'paragraph',
      children: [{ text: benefitGranted?.observation ? benefitGranted?.observation : '' }],
    }
  ])
  function useQueryParams() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const queryParams = useQueryParams();

  useEffect(() => {
    getBeneficioConcedido();
  }, []);


  async function getBeneficioConcedido() {
    const benefitGrantedId = queryParams.get('benefitGrantedId');
    try {
      if (benefitGrantedId) {
        const response = await BenefitService.getBeneficioConcedido(
          Number(benefitGrantedId),
          accessToken,
        );
        setBenefitGranted(response);
      }
    } catch (error: any) {
      //
    }
  }

  const toggleBlock = (editor: any, format: any) => {
    const isActive = isBlockActive(editor, format)
    const isList = LIST_TYPES.includes(format)
    const includeString: any = (n: any) => !Editor.isEditor(n) ? (SlateElement.isElement(n) && n.type) : '';

    Transforms.unwrapNodes(editor, {
      match: n =>
        LIST_TYPES.includes(includeString(n)),
      split: true,
    })
    const newProperties: Partial<SlateElement> = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  }

  const toggleMark = (editor: any, format: any) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  }

  const isBlockActive = (editor: any, format: any) => {
    const [match]: any = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })

    return !!match
  }

  const isMarkActive = (editor: any, format: any) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  }

  const BlockButton = ({ format, icon }: any) => {
    const editor = useSlate()
    return (
      <Button
        // active={isBlockActive(editor, format)}
        style={{ padding: '6px 16px 6px 0 ', minWidth: 'unset' }}
        onMouseDown={event => {
          event.preventDefault()
          toggleBlock(editor, format)
        }}
      >
        {icon}
      </Button>
    )
  }

  const MarkButton = ({ format, icon }: any) => {
    const editor = useSlate()
    return (
      <Button
        // active={isMarkActive(editor, format)}
        style={{ padding: '6px 16px 6px 0 ', minWidth: 'unset' }}
        onMouseDown={event => {
          event.preventDefault()
          toggleMark(editor, format)
        }}
      >
        {icon}
      </Button>
    )
  }

  return (
    <Grid container spacing={4} className={classes.containerForm}>
      <Grid item xs={12}>
        <span className={classes.infoTitle}>Observações</span>
      </Grid>

      <Grid item xs={12}>
        <Slate
          editor={editor}
          value={value}
          onChange={newValue => setValue(newValue)}
        >
          <div>
            <MarkButton format="bold" icon={<FormatBold />} />
            <MarkButton format="italic" icon={<FormatItalic />} />
            <MarkButton format="underline" icon={<FormatUnderlined />} />
            <MarkButton format="code" icon={<Code />} />
            <BlockButton format="heading-one" icon={<LooksOne />} />
            <BlockButton format="heading-two" icon={<LooksTwo />} />
            <BlockButton format="block-quote" icon={<FormatQuote />} />
            <BlockButton format="numbered-list" icon={<FormatListNumbered />} />
            <BlockButton format="bulleted-list" icon={<FormatListBulleted />} />
          </div>
          <Editable
            style={{ backgroundColor: '#FFFFFF', padding: '1px 12px', border: '1px solid #cccccc' }}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                  event.preventDefault()
                  const mark = HOTKEYS[hotkey]
                  toggleMark(editor, mark)
                }
              }
            }}
          />
        </Slate>
      </Grid>
      {/* <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            disabled={loading}
            variant="contained"
            className={classes.buttonRegister}
            onClick={addObservation}
          >
            {loading ? <CircularProgress size={25} /> : "Adicionar"}
          </Button>
        </Box>
      </Grid> */}
    </Grid>
  );
}

const Element = ({ attributes, children, element }: any) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

export default withStyles(styles)(ObservationField);