const styles: any = {
  tableContainer: {
    // height: '35vh',
    width: '100%',
    '& div.MuiDataGrid-root.MuiDataGrid-root': {
      backgroundColor: '#fff',
    },
    marginBottom: '1rem',
  },
  incomesContainer: {
    width: '35%',
    '& div.MuiDataGrid-root.MuiDataGrid-root': {
      backgroundColor: '#fff',
    }
  },
  incomesHeight: {
    // height: '50vh',
  },
};

export default styles;
