const styles: any = {
    main: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        marginBottom: 15,
        borderBottom: '2px solid #707070',
        justifyContent: 'space-between',
        color: '#177AFC',
    },
    headerIcon: {
        color: '#177AFC',
        cursor: 'pointer',
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        color: '#707070',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    mainItem: {
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'stretch',
    },
    cardImg: {
        maxHeight: '40px',
        minHeight: '40px',
    },
    cardInfoTitle: {
        color: '#707070',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        padding: '0 0 0 20px',
        fontSize: 15,
    },
    cardInfoTotal: {
        color: '#707070',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: 12,
    },
    cardInfoTotalDetail: {
        color: '#0A0A0A59',
        fontSize: 12,
        fontWeight: '500',
    },
};

export default styles;