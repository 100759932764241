const styles: any = {
    main: {
        display: 'flex',
        // flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: '#0A0A0A',
        fontSize: 12,
        fontWeight: 'bold',
    },
    infoTitle: {
        color: '#0A0A0A',
        fontSize: 12,
    },
    divisor: {
        backgroundColor: '#0A0A0A59',
        height: 1,
        width: '100%',
    },
    conditionInfo: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
    },
    conditionTitle: {
        color: '#0A4F9D',
        fontWeight: 'bold',
        fontSize: 12,
    },
    conditionSubtitle: {
        color: '#707070',
        fontSize: 12,
    },
    benefitTitle: {
        color: '#707070',
        fontWeight: 'bold',
        fontSize: 12,
    },
    benefitSubtitle: {
        color: '#707070',
        fontSize: 12,
    },
    titleList: {
        fontSize: 12,
        textTransform: 'uppercase',
        color: '#0A4F9D',
        fontWeight: 'bold',
    },
    nested: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    listContainer: {
        alignSelf: 'self-start',
    },
    grantBenefitContainer: {
        padding: '20px 0px 0px 35px',
    },
    grantBenefitBtn: {
        color: '#0A4F9D',
        border: '1px solid #0A4F9D',
    },
};

export default styles;