const styles: any = {
    flexRow: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 20,
    },
    title: {
        color: '#0A0A0A59',
        fontWeight: 'bold',
        marginRight: 20,
    },
    tabs: {
        borderBottom: '1px solid #A0A0A0',
        color: '#A0A0A0',
        width: '100%',
        padding: '0 20px',
        fontWeight: 'bold',
        // color: '#177AFC'
    },
    tab: {
        fontWeight: 'bold',
        // color: '#177AFC'
    },
};

export default styles;