import { useState, useRef, useContext } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Box, Button, CircularProgress, Grid, InputAdornment, withStyles } from '@material-ui/core';
import * as Yup from 'yup';

import withSnackbar from '../../../templates/WithSnackbar';

import styles from './styles';
import { useHistory } from 'react-router-dom';
import Loading from '../../public/Loading';
import MyTextInput from '../../../MyTextInput';
import { messages } from '../../../../constants/messages';
import { AuthContext } from '../../../../providers/Auth';
import DeliveryPostService from '../../../../services/DeliveryPostService';

type Props = { classes: any, login: Function, openSnackbar: any, auth: any, history: any };

const inputLabelProps = { style: { color: '#707070', fontWeight: 'bold' } };
const inputProps = {
  startAdornment: (
    <InputAdornment position="start" style={{ color: "#707070" }} />
  ),
};

interface CreatePostData {
  description: string;
}

function DeliveryPostsRegister(props: Props) {
  const { classes, openSnackbar } = props;

  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { accessToken } = useContext(AuthContext);

  const [isLoadingPage] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  function handleResetFields() {
    formRef.current?.reset();
  }

  async function handleCreateUser(data: CreatePostData) {
    setIsLoadingSubmit(true);
    const schema = Yup.object().shape({
      description: Yup.string().required(messages.emptyField)
    });

    try {
      formRef.current?.setErrors({});
      await schema.validate(data, { abortEarly: false });

      await DeliveryPostService.create(accessToken, data);

      handleResetFields();
      openSnackbar('success', 'Posto de Entrega cadastrado com sucesso');
      setTimeout(() => history.goBack(), 1400);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        formRef.current?.setErrors(errorMessages);
      } else {
        openSnackbar('error', error.message);
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  if (isLoadingPage) {
    return <Loading />
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ margin: '30px 40px' }}>
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <span className={classes.pageTitle}>Cadastrar Novo Posto de Entrega</span>
          <div className={classes.pageLine}></div>
        </Grid>

        <Form ref={formRef} onSubmit={handleCreateUser}>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: 40 }}>
            <Grid container spacing={4} className={classes.containerForm}>
              <Grid item xs={4}>
                <MyTextInput
                  name="description"
                  label="Descrição"
                  placeholder="Descrição"
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" gridGap={8}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.goBack()}
                    disabled={isLoadingSubmit}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoadingSubmit}
                  >
                    {
                      isLoadingSubmit ? (
                        <CircularProgress size={20} />
                      ) : 'Cadastrar'
                    }
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(withSnackbar(DeliveryPostsRegister));