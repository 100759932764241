/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
} from 'react';
import { withStyles, Grid } from '@material-ui/core';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

import CardMain from '../../../../../templates/CardMain';
import { HomeEntity } from '../../../../../../interfaces/entities/home.entity';
import { CitizenEntity } from '../../../../../../interfaces/entities/citizen.entity';

import styles from './styles';

type Props = {
  classes: any;
  home: HomeEntity;
  isConsult: boolean;
  indentifier?: string;
  onShowDetail: Function;
};

const LogradouroPage: React.FC<Props> = (props) => {
  const {
    classes,
    home,
    isConsult,
    indentifier,
    onShowDetail,
  } = props;
  const [homeResponsible, setHomeResponsible] = useState<CitizenEntity>();
  // const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    setHomeResponsible(home.responsible!);
  }, []);

  return (
    <Grid item md={6} xs={12} id={indentifier} className={classes.mainItem}>
      <CardMain>
        <Grid className={classes.flexColumn}>
          <Grid item xs={12} className={classes.header}>
            <Grid item xs={8} className={classes.flexRow}>
              <img alt="House" src="/assets/house_color.png" className={classes.cardImg} />
              <span className={classes.cardInfoTitle}>{`N° ${home.street_number || 'Não informado'}`}</span>
            </Grid>
            {isConsult && <DescriptionOutlinedIcon className={classes.headerIcon} onClick={() => onShowDetail(home)} />}
          </Grid>
          <Grid item xs={12} className={classes.flexRow} style={{ marginBottom: 10 }}>
            <PersonOutlineOutlinedIcon style={{ fontSize: 19, marginRight: 5, alignSelf: 'flex-start' }} />
            <Grid item className={classes.flexColumn}>
              <span className={classes.cardInfoTotal}>Responsável</span>
              <span className={classes.cardInfoTotalDetail}>{homeResponsible?.name || 'Não Informado'}</span>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.flexRow}>
            <RoomOutlinedIcon style={{ fontSize: 19, marginRight: 5, alignSelf: 'flex-start' }} />
            <Grid item className={classes.flexColumn}>
              <span className={classes.cardInfoTotal}>Complemento</span>
              <span className={classes.cardInfoTotalDetail}>{home.complement || 'Não Informado'}</span>
            </Grid>
          </Grid>
        </Grid>
      </CardMain>
    </Grid>
  );
}

export default withStyles(styles)(LogradouroPage);
