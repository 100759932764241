/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import isHotkey from 'is-hotkey';
import {
  Box,
  Grid,
  Modal,
  Button,
  withStyles,
  Typography,
  IconButton,
  Tooltip,
  LinearProgress,
} from '@material-ui/core';
import { ColDef, DataGrid, CellParams, GridOverlay } from '@material-ui/data-grid';
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  Code,
  LooksOne,
  LooksTwo,
  FormatQuote,
  FormatListNumbered,
  FormatListBulleted,
} from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';

import { Editable, withReact, useSlate, Slate } from 'slate-react'
import { Editor, Transforms, createEditor, Element as SlateElement } from 'slate'

import CardMain from '../../../../../templates/CardMain';

import { NewObservationEntity } from '../../../../../../interfaces/entities/new.observation.entity';

import styles from './styles';


type Props = {
  classes: any,
  handleError(error: string): void,
  occurrences: NewObservationEntity[],
  onNewOccurrence(occurrence: string): void,
  reloading?: boolean,
};

const HOTKEYS: any = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

interface OccurenceValue {
  type: string,
  children: { text: string }[],
}

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const OccurrenceField: React.FC<Props> = (props) => {
  const { classes, handleError, occurrences, onNewOccurrence, reloading } = props;
  const dateFns = new DateFnsUtils();

  const [viewValue, setViewValue] = useState<OccurenceValue[]>([]);
  const [openView, setOpenView] = useState<boolean>(false);

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editorCreate = useMemo(() => withReact(createEditor()), []);
  const editorView = useMemo(() => withReact(createEditor()), []);
  const [value, setValue] = useState<any[]>([
    {
      type: 'paragraph',
      children: [{ text: '' }],
    }
  ]);

  const columnWidth = 200;
  const occurenceCols: ColDef[] = [
    {
      field: 'last_update_user',
      headerName: 'Usuário',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => value || '(autor não encontrado)',
    },
    {
      field: 'created_at',
      headerName: 'Data da última atualização',
      width: columnWidth + 50,
      valueFormatter: ({ value }: CellParams) => dateFns.format(value as Date, 'dd/MM/yyyy HH:mm:ss'),
    },
    {
      field: 'description',
      headerName: 'Visualizar',
      width: columnWidth,
      renderCell: ({ value }: CellParams) => (
        <Tooltip title="Visualizar ocorrência">
          <IconButton onClick={() => handleClickView([{
            type: 'paragraph',
            children: [{ text: value as string }],
          }])}>
            <VisibilityRoundedIcon />
          </IconButton>
        </Tooltip>
      ),
    }
  ];

  function handleClickView(value: OccurenceValue[]) {
    setViewValue(value);
    setOpenView(true);
  };

  function handleCloseView() {
    handleCleanOccurrence();
    setOpenView(false);
  };

  function handleCleanOccurrence() {
    const cleanValue = [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      }
    ];
    setViewValue(cleanValue);
    setValue(cleanValue);
  };

  async function handleAddButtonClick() {
    const data: any = {
      occurrenceValue: value.map(element => {
        return {
          type: element.type,
          children: [element.children[0]]
        };
      }),
    };

    if (data.occurrenceValue[0].children && data.occurrenceValue[0].children[0].text !== "") {
      addOccurrence(data);
    }
  };

  function addOccurrence(data: any) {
    const { occurrenceValue } = data;
    let text = '';

    occurrenceValue.forEach((value: any) => {
      const paragraph = value.children[0].text;
      text += paragraph + '\n'
    });
    
    try {
      onNewOccurrence(text);
    } catch (error: any) {
      handleError(error?.message);
    } finally {
      handleCleanOccurrence();
    }
  };

  const toggleBlock = (editor: any, format: any) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);
    const includeString: any = (n: any) => !Editor.isEditor(n) ? (SlateElement.isElement(n) && n.type) : '';

    Transforms.unwrapNodes(editor, {
      match: n =>
        LIST_TYPES.includes(includeString(n)),
      split: true,
    });
    const newProperties: Partial<SlateElement> = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block);
    };
  };

  const toggleMark = (editor: any, format: any) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    };
  };

  const isBlockActive = (editor: any, format: any) => {
    const [match]: any = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match
  };

  const isMarkActive = (editor: any, format: any) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  };

  const BlockButton = ({ format, icon }: any) => {
    const editor = useSlate()
    return (
      <Button
        style={{ padding: '6px 16px 6px 0 ', minWidth: 'unset' }}
        onMouseDown={event => {
          event.preventDefault()
          toggleBlock(editor, format)
        }}
      >
        {icon}
      </Button>
    );
  };

  const MarkButton = ({ format, icon }: any) => {
    const editor = useSlate()
    return (
      <Button
        style={{ padding: '6px 16px 6px 0 ', minWidth: 'unset' }}
        onMouseDown={event => {
          event.preventDefault()
          toggleMark(editor, format)
        }}
      >
        {icon}
      </Button>
    );
  };

  return (
    <>
      <Grid container spacing={4} className={classes.containerForm}>
        <Grid item xs={12}>
          <span className={classes.infoTitle}>Descreva a ocorrência </span>
        </Grid>
        <Grid item xs={12}>
          <Slate
            editor={editorCreate}
            value={value}
            onChange={newValue => setValue(newValue)}
          // onBlur={handleChange}
          >
            <div>
              <MarkButton format="bold" icon={<FormatBold />} />
              <MarkButton format="italic" icon={<FormatItalic />} />
              <MarkButton format="underline" icon={<FormatUnderlined />} />
              <MarkButton format="code" icon={<Code />} />
              <BlockButton format="heading-one" icon={<LooksOne />} />
              <BlockButton format="heading-two" icon={<LooksTwo />} />
              <BlockButton format="block-quote" icon={<FormatQuote />} />
              <BlockButton format="numbered-list" icon={<FormatListNumbered />} />
              <BlockButton format="bulleted-list" icon={<FormatListBulleted />} />
            </div>
            <Editable
              style={{ backgroundColor: '#FFFFFF', padding: '1px 12px', border: '1px solid #cccccc' }}
              renderElement={renderElement}
              readOnly={false}
              renderLeaf={renderLeaf}
              // onBlur={handleChange}
              onKeyDown={event => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event as any)) {
                    event.preventDefault()
                    const mark = HOTKEYS[hotkey]
                    toggleMark(editorCreate, mark)
                  }
                }
              }}
            />
          </Slate>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Modal
            open={openView}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Grid item xs={5}>
              <CardMain>
                <Grid item xs={12} >
                  <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span>Ocorrência</span>
                    <Button>
                      <CloseIcon color="error" onClick={() => handleCloseView()} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Slate
                      editor={editorView}
                      value={viewValue as any}
                      onChange={occurrenceValue => setViewValue(occurrenceValue as any)}
                    >
                      <Editable
                        style={{ backgroundColor: '#FFFFFF', padding: '1px 12px', border: '1px solid #cccccc' }}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        readOnly
                        onKeyDown={event => {
                          for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event as any)) {
                              event.preventDefault()
                              const mark = HOTKEYS[hotkey]
                              toggleMark(editorView, mark)
                            }
                          }
                        }}
                      />
                    </Slate>
                  </Grid>
                </Grid>
              </CardMain>
            </Grid>
          </Modal>
        </Grid>
        {
          occurrences.length ? (
            <Grid item xs={12}>
              <Typography className={classes.infoTitle} variant="h6">Registro de ocorrências</Typography>
              <Box className={classes.ocurrenceTable}>
                <DataGrid
                  columns={occurenceCols}
                  rows={occurrences}
                  loading={reloading}
                  disableSelectionOnClick
                  autoHeight
                  localeText={{
                    footerTotalRows: 'de',
                    footerPaginationRowsPerPage: 'Ocorrências por página'
                  }}
                  components={{
                    LoadingOverlay: CustomLoadingOverlay,
                  }}
                />
              </Box>
            </Grid>
          )
          : null
        }
      </Grid>
    </>
  );
}

const Element = ({ attributes, children, element }: any) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

export default withStyles(styles)(OccurrenceField);
