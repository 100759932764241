const styles: any = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    containerForm: {
        padding: 50,
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center',
    },
    pageTitle: {
        color: '#0A0A0A59',
        fontWeight: 'bold',
        marginRight: '15px',
    },
    pageLine: {
        backgroundColor: '#0A0A0A59',
        flex: 1,
        height: 1,
        marginRight: '15px',
    },
    btnAdd: {
        backgroundColor: '#0A4F9D',
        color: '#FFFFFF',
        margin: '0 10px',
    },
    cardsContainer: {
        paddingLeft: '40px',
    },
    cardAddBeneficioContainer: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 8px #0000001F',
        borderRadius: 10,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    cardIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#E6E6E6',
        border: '2px solid #FFFFFF',
        borderRadius: '10px 0px 0px 10px',
    },
    cardInfo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#0A0A0A59',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '17px',
        textAlign: 'center',
        padding: '50px 20px',
    },
    cardInfoTitle: {
        color: '#0A0A0A59',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '16px',
    },
    buttonCancel: {
        width: '150px',
        fontWeight: 'bold',
        fontSize: '1.1rem',
        marginLeft: '15px',
        borderColor: '#D4586D',
        color: '#707070',
        textTransform: 'capitalize',
        "&:hover": {
            backgroundColor: '#D4586D',
            color: '#fff',
        },
    },
    buttonRegister: {
        width: '150px',
        fontWeight: 'bold',
        fontSize: '1.1rem',
        marginLeft: '15px',
        backgroundColor: '#004692',
        color: '#fff',
        textTransform: 'capitalize',
        "&:hover": {
            backgroundColor: '#408BCC'
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        padding: '30px',
        height: '100%',
    },
    divNotPermission: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
    },
    noResultsSpan: {
        color: '#0A4F9D',
        fontSize: 25,
        textAlign: 'center',
        marginTop: 10
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default styles;