const styles: any = {
  pageTitle: {
    color: '#0A0A0A59',
    fontWeight: 'bold',
    marginRight: '10px',
  },
  pageLine: {
    backgroundColor: '#0A0A0A59',
    flex: 1,
    height: 1,
  },
  containerForm: {
    padding: 30,
    justifyContent: 'center',
    backgroundColor: '#EEEEEE',
    border: '1px solid #0A0A0A59',
    borderRadius: 10,
  },
  containerFormBenefit: {
    padding: 30,
    backgroundColor: '#EEEEEE',
    border: '1px solid #0A0A0A59',
    borderRadius: 10,
    marginTop: '1rem',
  },
  buttonRegister: {
    marginLeft: '15px',
    color: '#fff',
    textTransform: 'capitalize',
  },
  buttonCancel: {
    borderColor: '#D4586D',
    color: '#707070',
    textTransform: 'capitalize',
    "&:hover": {
      backgroundColor: '#D4586D',
      color: '#fff',
    },
  },
  buttonSelection: {
    marginLeft: '15px',
    backgroundColor: '#004692',
    color: '#fff',
    textTransform: 'capitalize',
    "&:hover": {
      backgroundColor: '#408BCC'
    }
  },
  divCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  gridCircularProgress: {
    display: 'flex',
    height: '80vh',
    justifyContent: 'center',
    alignItems: 'center'
  },
  circularProgress: {
    color: '#0A4F9D'
  },
  birthDatePicker: {
    margin: '0px',
  },
  select: {
    width: '100%',
  },
  label: {
    color: '#707070',
    fontWeight: 'bold',
  },
  infoTitle: {
    color: '#0A0A0A59',
    fontWeight: '500'
  },
  infoSubTitle: {
    color: '#0A0A0A59',
    marginRight: '1rem',
    fontWeight: '500'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridContainerCardMain: {
    height: '90vh',
    overflow: 'auto',
    justifyContent: 'flex-end',
  },
  actionsButtonsContainer: {
    marginTop: '1rem',
  },
  cardInfoTitle: {
    color: '#0A0A0A59',
    fontWeight: 'bold',
    fontSize: 16,
  },
  cardInfoTotal: {
    fontSize: 16,
    marginLeft: '15px',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  dividerValidate: {
    margin: '1rem 0rem',
  },
  addCitizenBtn: {
    marginBottom: '0.5rem'
  },
};

export default styles;