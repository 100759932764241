/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import {
  Box,
  Grid,
  Button,
  Divider,
  Tooltip,
  IconButton,
  Typography,
  withStyles,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from "date-fns/locale/pt-BR";
import { ArrowBack, EditOutlined, Save } from '@material-ui/icons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import MySelect from '../../../MySelect';
import MySwitch from '../../../MySwitch';
import Loading from '../../public/Loading';
import MyTextInput from '../../../MyTextInput';
import withSnackbar from '../../../templates/WithSnackbar';
import withConfirmDialog, { WrappedType } from '../../../templates/withConfirmDialog';

import BenefitBreadTableService from '../../../../services/BenefitBreadTableService';
import { HomeEntity } from '../../../../interfaces/entities/home.entity';
import { StreetEntity } from '../../../../interfaces/entities/street.entity';
import { CitizenEntity } from '../../../../interfaces/entities/citizen.entity';
import { ResidentHomeEntity } from '../../../../interfaces/entities/resident-home.entity';

import { AuthContext } from '../../../../providers/Auth';
import HomeService from '../../../../services/HomeService';
import streetService from '../../../../services/StreetService';
import CitizenService from '../../../../services/CitizenService';
import BenefitService from '../../../../services/BenefitService';

import styles from './styles';
import neighborhoods from '../../../../constants/neighborhoods';
import registerStatusTypes from '../../../../constants/registerStatusTypes';
import { ATIVO as STATUS_ATIVO, DESLIGADO as STATUS_DESLIGADO } from '../../../../constants/status';
import OccurrenceField from './components/OccurrenceField';
import homeService from '../../../../services/HomeService';
import FamilyTable from './components/FamilyTable';
import { messages } from '../../../../constants/messages';
import { CitizenBenefitEntity } from '../../../../interfaces/entities/citizen-benefit.entity';
import { NewObservationEntity } from '../../../../interfaces/entities/new.observation.entity';
import { Benefits } from '../../../../enums/Benefits';

interface Props extends WrappedType {
  classes: any,
  login: Function,
  openSnackbar: any,
};

interface IncomesParam {
  id: number;
  description: string;
  ammount: number;
  citizen_id: number;
}

type InputHomeNames = 'district' | 'street_name' | 'street_number' | 'complement' | 'reference_point';

const inputLabelProps: any = { style: { color: '#707070', fontWeight: 'bold' } };
const inputProps: any = { startAdornment: (<InputAdornment position="start" style={{ color: "#707070" }} />) };

const BreadGrantBenefit: React.FC<Props> = (props) => {
  const { classes, openSnackbar, openConfirmDialog, } = props;

  const history = useHistory();
  const { url } = useRouteMatch();
  const dateFns = new DateFnsUtils();
  const { user, accessToken } = useContext(AuthContext);
  const { citizenId } = useParams<{ citizenId: string | undefined }>();
  function useQueryParams() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const queryParams = useQueryParams();

  const [home, setHome] = useState<HomeEntity>();
  const [isLoadingBenefitData, setIsLoadingBenefitData] = useState(false);
  const [streetsByDistrict, setStreetsByDistrict] = useState<StreetEntity[]>([]);
  const [family, setFamily] = useState<ResidentHomeEntity[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [suggestedValue, setSuggestedVaue] = useState(0);
  const [simulateMessage, setSimulateMessage] = useState('');
  const [statusSugester, setStatusSugester] = useState<any>({});
  const [incomePerCapita, setIncomePerCapita] = useState(0);
  const [checkForce, setCheckForce] = useState(false);
  const [citizen, setCitizen] = useState<CitizenEntity>();
  const [loadingConcederWithForce, setLoadingConcederWithForce] = useState(false);
  const [, setIsLoadingHome] = useState(false);
  const [, setShowSaveButton] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [reloadingOccurrences, setReloadingOcurrences] = useState(false);
  const [showNibButton, setShowNibButton] = useState(false);
  const [grantedBenefit, setGrantedBenefit] = useState<CitizenBenefitEntity>();
  const [isLoadingEditNib, setIsLoadingNib] = useState(false);
  const [occurrenceArray, setOccurrenceArray] = useState<NewObservationEntity[]>([]);
  const [nib, setNib] = useState();

  const addressFormRef = useRef<FormHandles>(null);
  const breadSimulateBenefitFormRef = useRef<FormHandles>(null);
  const breadConfirmBenefitFormRef = useRef<FormHandles>(null);
  const prontuarioFormRef = useRef<FormHandles>(null);

  useEffect(() => {
    loadBenefitData();
    getGrantedBenefit();
  }, []);

  useEffect(() => {
    reloadMembers();
  }, [refresh]);

  useEffect(() => {
    if (family && family.length > 0) {
      handleSimulateBenefit({
        nomeOperador: user?.name,
        nib: grantedBenefit?.nib,
      });
    }
  }, [family]);

  const verifyHomeChange = (event: any) => {
    const inputValue = event.target.value;
    const inputName: InputHomeNames = event.target.name;
    const homeValue = home ? home[inputName] : undefined;
    if (
      inputValue !== homeValue
    ) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }

  function navigateToCitizenRegister() {
    const benefitGrantedId = queryParams.get('benefitGrantedId');
    let returnPath = url;

    if (benefitGrantedId) {
      returnPath += `?benefitGrantedId=${benefitGrantedId}`;
    }
    history.push(`/app/cidadao/cadastrar?houseId=${citizen?.home_id}&returnPath=${returnPath}`);
  }

  function navigateToHomeRegister() {
    let returnPath = url;

    history.push(`/app/imoveis/${citizen?.home_id}/editar?returnPath=${returnPath}`);
  }

  function navigateToSocialRecordRegister() {
    const benefitGrantedId = queryParams.get('benefitGrantedId');
    let returnPath = url;

    if (benefitGrantedId) {
      returnPath += `?benefitGrantedId=${benefitGrantedId}`;
    }

    history.push(`/app/prontuario/cadastrar?citizenId=${citizenId}&benefitGrantedId=${benefitGrantedId}&returnPath=${returnPath}`);
  }

  async function loadBenefitData() {
    setIsLoadingBenefitData(true);
    if (citizenId) {
      const citizen = await getCitizen(Number(citizenId)); //TODO Verificar se essa função será utilizada
      if (citizen) {
        setCitizen(citizen);
        await getMembers(citizen.home_id);
        await getHome(citizen.home_id);
        setIsLoadingBenefitData(false);
      }
    }
  }

  async function getGrantedBenefit() {
    try {
      const benefitGrantedId = queryParams.get('benefitGrantedId');
      if (Number(benefitGrantedId)) {
        const response = await BenefitService.getBeneficioConcedido(Number(benefitGrantedId), accessToken);
        setGrantedBenefit(response);
        if (response.observations && response.observations.length > 0) {
          const { observations } = response;
          const newOccurrencesFromServer: NewObservationEntity[] = observations.map(
            (observation, index) => ({
              id: index,
              description: observation.description,
              created_at: new Date(observation.created_at),
              last_update_user: observation.last_update_user ? observation.last_update_user.name : '',
              synced: true,
            })
          );

          setOccurrenceArray(newOccurrencesFromServer);
        };
      }
    } catch (error: any) {
      handleError(error?.message);
    }
  }

  async function getCitizen(id: number) {
    try {
      const result = await CitizenService.getCitizen(id, accessToken);
      return result;
    } catch (error: any) {
      handleError(error?.message);
      return undefined;
    };
  };

  async function getMembers(homeId: number) {
    try {
      const response = await HomeService.getResidentes(
        homeId,
        accessToken,
        true,
        true,
      );
      // eslint-disable-next-line array-callback-return
      const family: any = response.results.map((resident) => {
        if (resident.incomes.length > 0) {
          const hasBpc = resident.incomes.some((income: { description: string }) => {
            return income.description === 'BPC';
          });

          resident.bpc = hasBpc;
        }

        return resident;
      });

      setFamily(family);
    } catch (error: any) {
      handleError('Falha ao carregar a composição familiar');
    }
  }

  async function getHome(homeId: number) {
    try {
      const response = await HomeService.getHome(homeId, accessToken);

      setHome(response);
      getStreetByDistrict(response.district);
    } catch (error: any) {
      handleError('Falha ao carregar o domicílio do cidadão');
    }
  }

  async function getStreetByDistrict(district: string) {
    try {
      const response = await streetService.searchLogradouros(district, accessToken);
      setStreetsByDistrict(response.results);
    } catch (error: any) {
      handleError('Falha ao carregar os logradouros');
    }
  }

  const reloadMembers = useCallback(async () => {
    if (citizen) {
      setReloading(true);
      setReloading(false);
    }
  }, [refresh]);

  function sumIncomes(incomes: IncomesParam[]) {
    let sum = 0;
    incomes.forEach((income) => {
      sum += income.ammount;
    });

    return sum;
  }

  const handleSimulateBenefit = async (data: any) => {
    let familyIncome = 0;

    const schema = Yup.object().shape({
      startDate: Yup.date().required(),
    });

    const familyMembers = family?.map((member) => {
      familyIncome += sumIncomes(member.incomes);

      return {
        responsible: member.responsible as boolean,
        bpc: Boolean(member.bpc),
        income: sumIncomes(member.incomes),
      };
    });

    try {
      await schema.validate({
        ...data,
        startDate: startDate,
      }, { abortEarly: false });

      if (familyMembers) {
        const response = await BenefitBreadTableService.simulate(
          familyMembers,
          accessToken
        );
        const rendaPerCappita = familyIncome / (family?.length || 1);

        setSuggestedVaue(response.suggested_value ? response.suggested_value : 0);
        setSimulateMessage(response.message);

        if (response.message !== 'Benefício autorizado!') {
          setStatusSugester(STATUS_DESLIGADO);
        } else {
          setStatusSugester({
            ...STATUS_ATIVO,
            name: 'Aprovado'
          });
        }

        setIncomePerCapita(rendaPerCappita);
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        handleError('Preencha os dados do benefício corretamente');
        return;
      }
      handleError(error?.message);
    } finally {
    }
  };

  function handleChangeCheckedForce(value: boolean) {
    setCheckForce(value);
  }

  async function concederBenefifcioWithForce() {
    const submitData = {
      benefit_id: 3,
      benefit_grant_status_id: breadConfirmBenefitFormRef.current?.getFieldValue('registerStatus'),
      value: breadConfirmBenefitFormRef.current?.getFieldValue('benefitValue'),
      started_at: dateFns.format(new Date(startDate), 'yyyy-MM-dd'),
      correction_justification: breadConfirmBenefitFormRef.current?.getFieldValue('justifyBenefit'),
      force: true,
      family_members: family?.map((member) => {
        return {
          id: member.id,
          bpc: member.bpc ? member.bpc : false,
          responsible: member.responsible,
          income: member.total_income || 0
        };
      }),
    };
    const benefitGrantedId = queryParams.get('benefitGrantedId');
    try {
      if (citizenId && benefitGrantedId) {
        await BenefitBreadTableService.atualizar(
          accessToken,
          Number(benefitGrantedId),
          submitData,
        );
        openSnackbar('success', 'Benefício atualizado com sucesso');
        setTimeout(() => {
          history.goBack();
        }, 1500);
      } else {
        await BenefitBreadTableService.conceder(
          accessToken,
          Number(citizenId),
          submitData,
        );
        openSnackbar('success', 'Benefício concedido com sucesso');
        setTimeout(() => {
          history.goBack();
        }, 1500);
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        handleError('Preencha os campos corretamente para conceder o benefício');
        return;
      }
      handleError(error?.message);
    }
  }

  async function handleConcederBeneficioWithForce(data: any) {
    setLoadingConcederWithForce(true);
    const schema = Yup.object().shape({
      registerStatus: Yup.number().required(messages.emptyField),
      benefitValue: Yup.number().required(messages.emptyField),
      justifyBenefit: Yup.string().required(messages.emptyField),
    });

    try {
      breadConfirmBenefitFormRef.current?.setErrors({})
      await schema.validate(data, { abortEarly: false });

      openConfirmDialog({
        title: 'Conceder benefício',
        message: 'Você realmente deseja conceder esse benefício?',
        confirmAction: concederBenefifcioWithForce,
      });
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        breadConfirmBenefitFormRef.current?.setErrors(errorMessages);
        handleError('Preencha os campos corretamente para conceder o benefício');
        return;
      }
    } finally {
      setLoadingConcederWithForce(false);
    }
  };

  async function handleConcederBeneficio(data: any) {
    setLoadingConcederWithForce(true);
    if (breadConfirmBenefitFormRef.current?.getFieldValue('checkedB')) {
      await handleConcederBeneficioWithForce(data);
    } else {
      let social_record = prontuarioFormRef.current?.getFieldValue('socialRecord');

      if (!social_record) {
        social_record = null;
      }

      const submitData = {
        benefit_id: 3,
        benefit_grant_status_id: statusSugester.id,
        value: suggestedValue,
        started_at: dateFns.format(new Date(startDate), 'yyyy-MM-dd'),
        nib: nib ? nib : null,
        social_record,
        family_members: family?.map((member) => {
          return {
            id: member.id,
            bpc: member.bpc ? member.bpc : false,
            responsible: member.responsible,
            income: member.total_income || 0
          };
        }),
        force: false,
      };

      try {
        const queryGrantId = queryParams.get('benefitGrantedId');
        let benefitGrantId = Number(queryGrantId);

        if (benefitGrantId) {
          await BenefitBreadTableService.atualizar(
            accessToken,
            benefitGrantId,
            submitData,
          );
        } else {
          const { id: responseGrantId } = await BenefitBreadTableService.conceder(
            accessToken,
            Number(citizenId),
            submitData,
          );

          if (responseGrantId) {
            benefitGrantId = Number(responseGrantId);
          }
        }

        await Promise.all(
          occurrenceArray.filter(
            (occurence) => !occurence.synced
          ).map(
            (unsyncedOccurrence) => BenefitService.registerObervationFromBenefit(benefitGrantId, accessToken, unsyncedOccurrence.description)
          )
        );

        openSnackbar('success', 'Benefício concedido com sucesso');

        setTimeout(() => {
          history.goBack();
        }, 1500);
      } catch (error: any) {
        handleError(error?.message);
      } finally {
        setLoadingConcederWithForce(false);
      }
    }
  }

  async function handleUpdateHome(data: any) {
    setIsLoadingHome(true);
    try {
      if (home) {
        await homeService.updateHome(accessToken, home.id, data);
        openSnackbar('success', 'Domicílio atualizado com sucesso');
        setShowSaveButton(false);
      }
    } catch (error: any) {
      handleError(error?.message);
    } finally {
      setIsLoadingHome(false);
    }
  }

  function handleError(e: any) {
    openSnackbar('error', e);
  };

  function handleToggleRefresh() {
    setRefresh(prevState => !prevState);
  }

  function handleChangeDate(date: any) {
    setStartDate(date);
  }

  async function handleNewOccurrence(occurrence: string) {
    const newOccurrence: NewObservationEntity = {
      id: occurrenceArray.length + 1,
      description: occurrence,
      created_at: new Date(),
      last_update_user: user?.name,
      synced: false,
    };
    setReloadingOcurrences(true);

    const benefitGrantId = queryParams.get('benefitGrantedId');

    if (benefitGrantId) {
      await BenefitService.registerObervationFromBenefit(Number(benefitGrantId).valueOf(), accessToken, newOccurrence.description);
      newOccurrence.synced = true;
    }

    const newOccurrenceArray = [...occurrenceArray, newOccurrence];
    setOccurrenceArray(newOccurrenceArray);

    setReloadingOcurrences(false);
  }

  function generatePdf() {
    const benefitGrantedId = queryParams.get('benefitGrantedId');
    if (!!benefitGrantedId) {
      const link = BenefitBreadTableService.generateBenefitValidate(
        Number(citizenId),
        incomePerCapita,
        simulateMessage,
        statusSugester.name,
        suggestedValue,
        Number(benefitGrantedId)
      );
      window.open(link, '_blank');
    } else {
      const link = BenefitBreadTableService.generateBenefitValidate(
        Number(citizenId),
        incomePerCapita,
        simulateMessage,
        statusSugester.name,
        suggestedValue,
      );
      window.open(link, '_blank');
    }
  }

  async function handleUpdateNib() {
    setIsLoadingNib(true);
    const schema = Yup.object().shape({
      nib: Yup.string().required(messages.emptyField).typeError('Tipo inválido'),
    });
    const data = {
      nib: breadSimulateBenefitFormRef.current?.getFieldValue('nib'),
    };

    try {
      breadSimulateBenefitFormRef.current?.setErrors({});
      await schema.validate(data, { abortEarly: false });

      const benefitGrantedId = queryParams.get('benefitGrantedId');
      if (benefitGrantedId !== null) {
        await BenefitService.updateNib(
          accessToken,
          Number(benefitGrantedId),
          data.nib,
        );
        setShowNibButton(false);
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        breadSimulateBenefitFormRef.current?.setErrors(errorMessages);
      } else {
        handleError(error?.message);
      }
    } finally {
      setIsLoadingNib(false);
    }
  }

  if (isLoadingBenefitData) {
    return (
      <Loading />
    );
  }

  return (
    <Grid container style={{ margin: '30px 0px 30px 40px', width: 'calc(100% - 112px)' }}>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title="Voltar para a página anterior">
          <IconButton onClick={() => history.goBack()}>
            <ArrowBack />
          </IconButton>
        </Tooltip>
        <Typography variant="subtitle1" className={classes.pageTitle}>Adicionar Beneficiário</Typography>
        <div className={classes.pageLine}></div>
      </Grid>

      <Grid item xs={12} style={{ marginTop: 40 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <Form
              initialData={{
                ...home,
              }}
              ref={addressFormRef}
              onSubmit={handleUpdateHome}
            >
              <Grid container spacing={4} className={classes.containerForm}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" className={classes.infoTitle}>
                    Dados Residenciais
                  </Typography>
                </Grid>
                <>
                  <Grid item xs={6}>
                    <MySelect
                      disabled
                      label="Bairro"
                      name="district"
                      options={neighborhoods.map((type) => ({ value: type.name, label: type.name }))}
                      onChange={event => {
                        getStreetByDistrict(event.target.value as string)
                        verifyHomeChange(event);
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <MySelect
                      disabled
                      required
                      options={streetsByDistrict.map((type) => ({ value: type.name, label: type.name }))}
                      label="Logradouro*"
                      name="street_name"
                      onChange={verifyHomeChange}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <MyTextInput
                      disabled
                      required
                      name="street_number"
                      label="N°"
                      type="number"
                      placeholder="N°"
                      onChange={verifyHomeChange}
                      InputLabelProps={inputLabelProps}
                      InputProps={inputProps}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <MyTextInput
                      disabled
                      name="complement"
                      label="Complemento"
                      placeholder="Complemento"
                      onChange={verifyHomeChange}
                      InputLabelProps={inputLabelProps}
                      InputProps={inputProps}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <MyTextInput
                      disabled
                      name="reference_point"
                      label="Ponto de Referência"
                      onChange={verifyHomeChange}
                      placeholder="Ex.: Próximo à Praça da Sé"
                      InputLabelProps={inputLabelProps}
                      InputProps={inputProps}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <MyTextInput
                      disabled
                      name="cep"
                      label="CEP"
                      onChange={verifyHomeChange}
                      InputLabelProps={inputLabelProps}
                      InputProps={inputProps}
                    />
                  </Grid>
                  <Grid style={{ marginTop: 15, marginLeft: '90%', width: '100px' }}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.buttonRegister}
                      onClick={navigateToHomeRegister}
                    >
                      Editar
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Form>
          </Grid>

          <Grid item xs={12}>
            <Form
              ref={prontuarioFormRef}
              initialData={{ socialRecord: grantedBenefit?.social_record }}
              onSubmit={() => { }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={4} className={classes.containerFormBenefit}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" className={classes.infoTitle}>
                        Pasta
                      </Typography>
                    </Grid>
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <MyTextInput
                            disabled
                            name="socialRecord"
                            InputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                          />
                        </Grid>
                        <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
                          {
                            grantedBenefit ?
                              (
                                <Tooltip title="Editar">
                                  <IconButton onClick={navigateToSocialRecordRegister}>
                                    <EditOutlined />
                                  </IconButton>
                                </Tooltip>
                              )
                              : <></>
                          }
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} className={classes.containerFormBenefit}>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="subtitle1" className={classes.infoTitle}>Composição Familiar</Typography>
                      <Button
                        color="primary"
                        variant="outlined"
                        endIcon={<AddCircleIcon />}
                        className={classes.addCitizenBtn}
                        onClick={navigateToCitizenRegister}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                    <FamilyTable
                      family={family}
                      reloading={reloading}
                      homeId={citizen?.home_id as number}
                      handleToggleRefresh={handleToggleRefresh}
                      benefitGrantedId={`${queryParams.get('benefitGrantedId')}`}
                      reloadFamily={async () => {
                        await getMembers(citizen?.home_id as number);
                      }} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '2rem' }}>
                <OccurrenceField
                  occurrences={occurrenceArray}
                  onNewOccurrence={handleNewOccurrence}
                  handleError={handleError}
                  reloading={reloadingOccurrences}
                />
              </Grid>
              <Grid item xs={12} className={classes.containerForm} style={{ marginTop: '2rem' }}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    onClick={generatePdf}
                    variant='outlined'
                    color="primary"
                    className={classes.buttonPDF}
                  >
                    Gerar pdf
                  </Button>
                </Box>
                <Form
                  initialData={{
                    nomeOperador: user?.name,
                    nib: grantedBenefit?.nib,
                  }}
                  ref={breadSimulateBenefitFormRef}
                  onSubmit={() => { }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" className={classes.infoTitle}>
                            Dados do Benefício
                          </Typography>
                        </Grid>
                        <>
                          <Grid item xs={3}>
                            <Grid container spacing={1}>
                              <Grid item xs={8}>
                                <MyTextInput
                                  name="nib"
                                  disabled={!!queryParams.get('benefitGrantedId') && !showNibButton}
                                  InputProps={inputProps}
                                  label="NIB"
                                  InputLabelProps={inputLabelProps}
                                  onChange={(e) => {
                                    setNib(e.target.value);
                                  }}
                                  placeholder="Digite o NIB..."
                                />
                              </Grid>
                              <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                                {
                                  !showNibButton ? (
                                    <Tooltip title="Habilitar edição">
                                      <IconButton onClick={() => setShowNibButton(true)}>
                                        <EditOutlined />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      disabled={isLoadingEditNib}
                                      endIcon={isLoadingEditNib ? <CircularProgress size={22} /> : <Save />}
                                      onClick={handleUpdateNib}
                                    >
                                      Salvar
                                    </Button>
                                  )
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                              <KeyboardDatePicker
                                name="startDate"
                                className={classes.birthDatePicker}
                                fullWidth
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="data-inicio-beneficio"
                                label="Data de vigência do benefício"
                                value={startDate}
                                onChange={date => handleChangeDate(date)}
                                invalidDateMessage="Formato de data inválido"
                                KeyboardButtonProps={{
                                  'aria-label': 'mudar data',
                                }}
                                placeholder="Data de Vigência"
                                InputLabelProps={inputLabelProps}
                                InputProps={inputProps}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <MyTextInput
                              disabled
                              label="Nome do Operador"
                              name="nomeOperador"
                              placeholder="Nome do operador..."
                              InputLabelProps={inputLabelProps}
                              InputProps={inputProps}
                            />
                          </Grid>
                        </>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
                <Divider className={classes.dividerValidate} />
                <Box>
                  <Grid container direction="column" spacing={2}>
                    <Grid xs={12}>
                      <Typography variant="subtitle1" className={classes.infoSubTitle}>Resumo Familiar</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography variant="body1" className={classes.cardInfoTitle}>Quantidade de membros: </Typography>
                        <Typography variant="body1" className={classes.cardInfoTotal}> {family?.length} </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography variant="body1" className={classes.cardInfoTitle}>Renda Per Capita: </Typography>
                        <Typography variant="body1" className={classes.cardInfoTotal}>
                          {
                            new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                            }).format(incomePerCapita)
                          }
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography variant="body1" className={classes.cardInfoTitle}>Parecer: </Typography>
                        <Typography variant="body1" className={classes.cardInfoTotal}>
                          {simulateMessage}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider className={classes.dividerValidate} />
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Typography variant="subtitle1" className={classes.infoSubTitle}>Benefício</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography variant="body1" className={classes.cardInfoTitle}>Status Sugerido: </Typography>
                        <Typography variant="body1" className={classes.cardInfoTotal}>
                          {statusSugester.name}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <Typography variant="body1" className={classes.cardInfoTitle}>Valor Sugerido: </Typography>
                        <Typography variant="body1" className={classes.cardInfoTotal}>
                          {
                            new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                            }).format(suggestedValue || 0)
                          }
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Form
                        ref={breadConfirmBenefitFormRef}
                        initialData={{
                          benefitValue: suggestedValue,
                          justifyBenefit: grantedBenefit?.correction_justification,
                        }}
                        onSubmit={handleConcederBeneficio}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12} className={classes.cardInfoTitle}>
                            <Typography variant="inherit">Selecione caso queira alterar o status e o valor</Typography>
                            <MySwitch
                              name="checkedB"
                              onChange={(event: any) => handleChangeCheckedForce(event.target.checked)}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <MySelect
                              disabled={!checkForce}
                              label="Status Atualizado:"
                              name="registerStatus"
                              options={registerStatusTypes.filter((status) => status.benefits.indexOf(Benefits.BREAD) !== -1).map((type) => ({ value: type.id, label: type.name }))}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <MyTextInput
                              disabled={!checkForce}
                              label="Valor atualizado"
                              name="benefitValue"
                              placeholder="R$ 0,00"
                              type="number"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" style={{ color: "#707070" }} />
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.select} style={{ marginTop: 10 }}>
                            <MyTextInput
                              disabled={!checkForce}
                              name="justifyBenefit"
                              label="Justificativa"
                              placeholder="Descreva o motivo da alteração"
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 10 }}>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <Button
                              variant="contained"
                              className={classes.buttonCancel}
                              disabled={loadingConcederWithForce}
                              onClick={() => history.goBack()}
                            >
                              Cancelar
                            </Button>
                            <Button
                              color="primary"
                              type="submit"
                              disabled={loadingConcederWithForce}
                              variant="contained"
                              className={classes.buttonRegister}
                            >
                              {loadingConcederWithForce ? <CircularProgress size={25} /> : "Confirmar benefício"}
                            </Button>
                          </Box>
                        </Grid>
                      </Form>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
};

export default withStyles(styles)(withSnackbar(
  withConfirmDialog(BreadGrantBenefit),
));
