import Client from './api/api';
import { SimulatePasResponse } from '../interfaces/responses/simulate-pas.response';
import errorService from './ErrorService';
import formatChartLabels from '../utils/formatChartLabels';
import races from '../constants/races';
import sexes from '../constants/sexes';
import benefitStatus from '../constants/status';
interface ArrayType {
  [key: string]: string | number;
  total: number;
}

interface ResponseResults<Type> {
  results: Type[];
}

interface ResponseChartData {
  labels: string[];
  data: number[];
}

class BenefitPasService {
  private apiInstance = Client('api');
  private config = (token: string) => ({ headers: { Authorization: `Bearer ${token}` } });
  private DEFAULT_REF = 'pas';
  private route = `${process.env[`REACT_APP_API_URL`]}/documents`;

  public async simulate(
    family_members: {
      responsible: boolean;
      bpc: boolean;
      income: number;
    }[],
    token: string,
  ): Promise<SimulatePasResponse> {
    try {
      const response = await this.apiInstance.post(`/${this.DEFAULT_REF}/simulate`, {
        family_members
      }, this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async conceder(
    token: string,
    citizenId: number,
    body: {
      benefit_id: number,
      benefit_grant_status_id: number,
      value: number,
      correction_justification?: string,
      started_at: string,
      validation_code?: string
      force?: boolean
    }
  ): Promise<any> {
    try {
      const response = await this.apiInstance.post(`/${this.DEFAULT_REF}/citizens/${citizenId}`, body, this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async atualizar(
    token: string,
    benefitGrantedId: number,
    body: {
      benefit_id: number,
      benefit_grant_status_id: number,
      value: number,
      correction_justification?: string,
      started_at: string,
      validation_code?: string,
      force?: boolean,
      family_members: any
    }
  ): Promise<any> {
    try {
      const response = await this.apiInstance.put(`/${this.DEFAULT_REF}/granted/${benefitGrantedId}`, body, this.config(token));

      return response;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public generateBenefitValidate(
    citizenId: number,
    rendaCapita: number,
    simulateMessage: string,
    suggestedStatus: string,
    suggestedValue: number,
    benefitGrantedId?: number,
  ) {
    try {
      return `${this.route}/benefits/pas/validate?citizen_id=${citizenId}&renda_capita=${rendaCapita}&simulate_message=${simulateMessage}&suggested_status=${suggestedStatus}&suggested_value=${suggestedValue}&benefit_granted_id=${benefitGrantedId}`;
    } catch (error: any) {
      errorService.handleError(error);
    }
  }

  private sortAlphabetic(a: any, b: any) {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  }

  private formatBarChartData(scope: string, array: ArrayType[], constArray?: any[]) {
    let labels: string[] = [];
    let data: number[] = [];
    if (scope === 'benefit_year') {
      array.sort((a, b) => {
        return Number(a['benefit_year']) - Number(b['benefit_year']);
      });
    }
    array.forEach((item) => {
      const attrType = item[scope];
      if (typeof attrType === 'string' || scope === 'benefit_year') {
        labels.push(attrType as string);
        data.push(item.total);
      }

      if (typeof attrType === 'number' && !!constArray) {
        // eslint-disable-next-line array-callback-return
        const newConstArray = constArray.filter((element, i) => {
          if (constArray.indexOf(element) === i) return element.name;
        });
        labels = formatChartLabels(newConstArray);
        constArray.sort(this.sortAlphabetic).forEach((type, index) => {
          if (type.id === attrType) {
            data[index] = item.total;
          }
        });
      }
    });

    return {
      labels,
      data,
    };
  }

  public async getTotalBeneficiaryByStatus(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const { data } = await this.apiInstance
        .get<ResponseResults<{ benefit_grant_status_id: number, total: number }>>(
          `/panels/benefits/pas/status/counts`, this.config(token)
        );

      return this.formatBarChartData(
        'benefit_grant_status_id',
        data.results,
        benefitStatus,
      );
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryByYear(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const { data } = await this.apiInstance
        .get<ResponseResults<{ benefit_year: string, total: number }>>(
          `/panels/benefits/pas/year/counts`,
          this.config(token)
        );

      return this.formatBarChartData('benefit_year', data.results);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryByGender(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const { data } = await this.apiInstance
        .get<ResponseResults<{ gender_id: number, total: number }>>(
          `/panels/benefits/pas/gender/counts`,
          this.config(token)
        );

      return this.formatBarChartData('gender_id', data.results, sexes);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryByComplexion(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const { data } = await this.apiInstance
        .get<ResponseResults<{ complexion_id: number, total: number }>>(
          `/panels/benefits/pas/complexion/counts`,
          this.config(token)
        );

      return this.formatBarChartData('complexion_id', data.results, races);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryRegistered(
    token: string,
  ): Promise<{ total: number }> {
    try {
      const { data } = await this.apiInstance
        .get(
          `/panels/benefits/pas/registered/counts`,
          this.config(token)
        );

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryIncomplete(
    token: string,
  ): Promise<{ total: number }> {
    try {
      const { data } = await this.apiInstance
        .get(
          `/panels/benefits/pas/incomplete/counts`,
          this.config(token)
        );

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }
}

export default new BenefitPasService();
