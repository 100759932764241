import React, { useContext, useState, useRef } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { Grid, withStyles, InputAdornment } from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';

import { AuthContext } from "../../../../providers/Auth";
import withSnackbar from '../../../templates/WithSnackbar';

import { messages } from '../../../../constants/messages';

import styles from './styles';
import ButtonMain from '../../../templates/ButtonMain';
import MyTextInput from '../../../MyTextInput';

type Props = { classes: any, login: Function, openSnackbar: any, auth: any };

interface FormLoginData {
  username: string;
  password: string;
}

interface FormForgotPasswordData {
  email: string;
}


const Login: React.FC<Props> = (props) => {
  const { login } = useContext(AuthContext);
  const { classes, openSnackbar } = props;

  const [modalForgetPasswordIsVisible, setModalForgetPasswordIsVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const formLoginRef = useRef<FormHandles>(null);
  const formForgotPasswordRef = useRef<FormHandles>(null);


  function handleError(e: any) {
    const { openSnackbar } = props;
    openSnackbar('error', e);
  }

  const onSendEmail = async (data: FormForgotPasswordData) => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().email(messages.invalidEmail).required(messages.emptyFields),
      });

      await schema.validate(data);

      openSnackbar('success', 'Email de redefinição enviado com sucesso');
      setTimeout(() => {
        setModalForgetPasswordIsVisible(false);
      }, 1500);
    } catch (err: any) {
      handleError(err?.message);
    }
  };

  const renderModalForgetPassword = () => {
    if (!modalForgetPasswordIsVisible) return null;
    return (
      <div className={classes.viewModalBackground}>
        <div className={classes.viewBackground} onClick={() => setModalForgetPasswordIsVisible(false)}></div>
        <Grid item xs={4} className={classes.viewModal}>
          <span className={classes.titleModal}>Esqueci minha senha</span>
          <Form ref={formForgotPasswordRef} onSubmit={onSendEmail} className={classes.formForgotContainer}>
            <MyTextInput
              required
              name="email"
              label="E-mail"
              placeholder="Insira seu e-mail"
              InputLabelProps={{
                style: { color: '#707070', fontWeight: 'bold' },
              }}
              InputProps={{
                style: { width: '100%' },
                startAdornment: (
                  <InputAdornment position="start" style={{ color: "#707070" }} />
                ),
              }}
            />
            <div className={classes.viewRow}>
              {/* <ButtonMain
              onPress={() => setModalForgetPasswordIsVisible(false)}
              title="Cancelar"
              stylesButton={styles.buttonCancelModal}
            /> */}
              <ButtonMain type="submit" loading={false} title="Enviar" />
            </div>
          </Form>
        </Grid>
      </div>
    );
  }

  async function onSubmit(data: FormLoginData) {
    try {
      setLoading(true);
      const schema = Yup.object().shape({
        username: Yup.string().required(messages.emptyField),
        password: Yup.string().required(messages.emptyField).min(6, 'A senha precisa conter no minímo 6 dígitos'),
      });

      await schema.validate(data, { abortEarly: false });

      await login(data.username, data.password);

    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        err.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        formLoginRef.current?.setErrors(errorMessages);
      } else {
        handleError(err?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container className={classes.mainGrid}>
      {renderModalForgetPassword()}
      <Form className={classes.formContainer} ref={formLoginRef} onSubmit={onSubmit}>
        <Grid container item lg={3} md={4} sm={7} xs={9} spacing={2} className={classes.cardLogin}>
          <Grid item xs={12} className={classes.divCenter}>
            <img alt="Logo SFC" src="/assets/sfc_logo_rect.png" className={classes.logoSfc} />
          </Grid>
          <Grid item xs={12}>
            <MyTextInput
              name="username"
              label="Usuário"
              placeholder="Digite seu usuário"
              InputLabelProps={{
                style: { color: '#707070', fontWeight: 'bold' },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ color: "#707070" }}>
                    <PersonOutlineOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MyTextInput
              name="password"
              type="password"
              label="Senha"
              placeholder="Digite sua senha"
              InputLabelProps={{
                style: { color: '#707070', fontWeight: 'bold' },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ color: "#707070" }}>
                    <VpnKeyOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.divButton}>
            <ButtonMain type="submit" loading={loading} title="Iniciar Sessão" />
          </Grid>
          {/* <Grid item xs={12} className={classes.divCenter}>
            <button type="button" onClick={() => setModalForgetPasswordIsVisible(true)} className={classes.linkForgot}>Esqueci minha senha</button>
          </Grid> */}
          <Grid item xs={12} className={classes.divCenter}>
            <img alt="Logo SFC" src="/assets/logo_acelera_horizontal.png" className={classes.logoAcelera} />
          </Grid>
        </Grid>
      </Form>
    </Grid>
  );
}

export default withStyles(styles)(withSnackbar(Login));