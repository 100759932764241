const styles: any = {
  button: {
    width: '100%',
    height: '100%',
    minHeight: '75px',
    flex: 1,
  },
  divButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spanButton: {
    fontSize: 10,
    marginTop: 5,
  },
  boxScroll: {
    marginTop: 10,
    maxHeight: '200px',
    overflow: 'auto',
  },
  logBox: {
    border: '1px solid #CCCCCC',
    padding: '2px 5px',
    display: 'flex',
    // alignItems: 'center',
  },
  logSpan: {
    fontSize: 13,
  },
  logDateSpan: {
    fontSize: 13,
    marginRight: 10,
  },
  buttonCancel: {
    // height: '56px',
    // fontWeight: 'bold',
    // fontSize: '1.1rem',
    marginLeft: '15px',
    borderColor: '#D4586D',
    color: '#707070',
    textTransform: 'capitalize',
    flex: 1,
    "&:hover": {
      backgroundColor: '#D4586D',
      color: '#fff',
    },
  },
  buttonRegister: {
    // height: '56px',
    // fontWeight: 'bold',
    // fontSize: '1.1rem',
    marginLeft: '15px',
    backgroundColor: '#004692',
    color: '#fff',
    textTransform: 'capitalize',
    flex: 1,
    "&:hover": {
      backgroundColor: '#408BCC'
    }
  },
  accordionDocuments: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },

  },
  accordionSumDocuments: {
    padding: 0,
  }
};

export default styles;