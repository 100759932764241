import { useState, useRef, useContext, useMemo, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Box, Button, CircularProgress, Grid, InputAdornment, withStyles } from '@material-ui/core';
import * as Yup from 'yup';

import withSnackbar from '../../../templates/WithSnackbar';

import styles from './styles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Loading from '../../public/Loading';
import MyTextInput from '../../../MyTextInput';
import MySelect from '../../../MySelect';
import typesUser from '../../../../constants/typesUser';
import { messages } from '../../../../constants/messages';
import { AuthContext } from '../../../../providers/Auth';
import UserService from '../../../../services/UserService';
import { UserEntity } from '../../../../interfaces/entities/user.entity';

type Props = { classes: any, login: Function, openSnackbar: any, auth: any, history: any };

const inputLabelProps = { style: { color: '#707070', fontWeight: 'bold' } };
const inputProps = {
  startAdornment: (
    <InputAdornment position="start" style={{ color: "#707070" }} />
  ),
};

interface UserData {
  name: string;
  email: string;
  login: string;
  password?: string;
  active: boolean;
  user_type_id: number;
}

function UserRegister(props: Props) {
  const { classes, openSnackbar } = props;

  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { accessToken, user } = useContext(AuthContext);
  const { userId } = useParams<{ userId: string | undefined }>();
  const [choosedUser, setChoosedUser] = useState<UserEntity>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  function useQueryParams() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const queryParams = useQueryParams();

  useEffect(() => {
    if (userId) {
      getUser();
    }
  }, []);

  async function getUser() {
    setIsLoading(true);
    try {
      let choosedUser = await UserService.get(accessToken, Number(userId));

      setChoosedUser(choosedUser);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleError(e: any) {
    openSnackbar("error", e.message);
  }

  function handleResetFields() {
    formRef.current?.setFieldValue('active', '');
    formRef.current?.setFieldValue('user_type_id', '');
    formRef.current?.reset();
  }

  async function handleCreateUser(data: UserData) {
    setIsLoadingSubmit(true);
    const schema = Yup.object().shape({
      name: Yup.string().required(messages.emptyField),
      email: Yup.string().required(messages.emptyField),
      login: Yup.string().required(messages.emptyField),
      user_type_id: Yup.mixed().required(messages.emptyField),
      password: Yup.string()
        .min(4, 'A senha deve contér pelo menos 4 dígitos')
        .max(20, 'A senha deve contér no máximo 20 dígitos')
        .nullable().test({
          message: messages.emptyField,
          test: () => userId || data.password ? true : false,
        })
    });

    try {
      formRef.current?.setErrors({});
      await schema.validate(data, { abortEarly: false });

      if (choosedUser) {
        await UserService.update(accessToken, data, choosedUser.id);
        openSnackbar('success', 'Usuário atualizado com sucesso');

      } else {
        await UserService.create(accessToken, data);
        openSnackbar('success', 'Usuário cadastrado com sucesso');
      }

      handleResetFields();
      setTimeout(() => history.goBack(), 1400);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        formRef.current?.setErrors(errorMessages);
      } else {
        openSnackbar('error', error.message);
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ margin: '30px 40px' }}>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <span className={classes.pageTitle}>{userId ? 'Editar ' : 'Cadastrar Novo '}Usuário</span>
            <div className={classes.pageLine}></div>
          </Grid>

          {!isLoading ? (
            <Form ref={formRef} onSubmit={handleCreateUser}
              initialData={{
                name: choosedUser?.name,
                email: choosedUser?.email,
                login: choosedUser?.login,
                active: choosedUser?.active,
                user_type_id: choosedUser?.user_type_id,
              }}
            >
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: 40 }}>
                <Grid container spacing={4} className={classes.containerForm}>
                  <Grid item xs={6}>
                    <MyTextInput
                      name="name"
                      label="Nome"
                      placeholder="Nome do operador"
                      InputLabelProps={inputLabelProps}
                      InputProps={inputProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MySelect
                      name="user_type_id"
                      label="Tipo de usuário"
                      options={typesUser.map(type => ({ value: type.id, label: type.name }))}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MyTextInput
                      name="email"
                      label="E-mail"
                      placeholder="E-mail do operador"
                      InputLabelProps={inputLabelProps}
                      InputProps={inputProps}
                    />
                  </Grid>
                  <Grid item xs={6} >
                    <MyTextInput
                      name="login"
                      label="Login"
                      placeholder="Login para o operador"
                      InputLabelProps={inputLabelProps}
                      InputProps={inputProps}
                    />
                  </Grid>
                  {!userId &&
                    <Grid item xs={12} >
                      <MyTextInput
                        name="password"
                        label="Senha"
                        placeholder="Senha para o usuário"
                        type="password"
                        InputLabelProps={inputLabelProps}
                        InputProps={inputProps}
                      />
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" gridGap={8}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => history.goBack()}
                        disabled={isLoadingSubmit}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoadingSubmit}
                      >
                        {
                          isLoadingSubmit ? (
                            <CircularProgress size={20} />
                          ) : choosedUser ? ('Editar') : ('Cadastrar')
                        }
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )
            : <Loading />
          }
        </Grid>
      </Grid>
    </>

  );
}

export default withStyles(styles)(withSnackbar(UserRegister));