import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import BenefitList from '../../../../components/pages/private/BenefitList';
import BreadTableGrantBenefit from '../../../../components/pages/private/BreadTableGrantBenefit';
import BreadTableGrantSearch from '../../../../components/pages/private/BreadTableGrantSearch';

const PaoRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <BenefitList benefit={3} />
      </Route>
      <Route path={`${path}/novo`}>
        <BreadTableGrantSearch />
      </Route>
      <Route path={`${path}/cidadao/:citizenId/conceder`}>
        <BreadTableGrantBenefit />
      </Route>
      <Route path={`${path}/cidadao/:citizenId/editar`}>
        <BreadTableGrantBenefit />
      </Route>
    </Switch>
  );
}

export default PaoRoutes;
