import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  MoreVert,
  ExpandMore,
} from '@material-ui/icons';
import {
  Menu,
  MenuItem,
  MenuList,
  Accordion,
  Typography,
  IconButton,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Grid,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { messages } from '../../../../../../constants/messages';
import withSnackbar from '../../../../../templates/WithSnackbar';

import CitizenService from '../../../../../../services/CitizenService';

import styles from './styles';
import CardMain from '../../../../../templates/CardMain';
import { Form } from '@unform/web';
import MyTextInput from '../../../../../MyTextInput';
import ButtonMain from '../../../../../templates/ButtonMain';

type Props = {
  classes: any,
  rowData: any,
  citizenId: string,
  benefitGrantedId: string,
  handleError: any;
  history: any,
  openSnackbar: any,
  openRecord?(): void;
};

interface ViaFormData {
  viaReason: string;
  viaValue: number;
  bankManager: string;
}

function BeneficiaryMenuPas(props: Props) {
  const {
    classes,
    rowData,
    citizenId,
    benefitGrantedId,
    openRecord
  } = props;
  const inputLabelProps: any = { style: { color: '#707070', fontWeight: 'bold' } };
  const history = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const viaFormRef = useRef<FormHandles>(null);

  useEffect(() => {
    if (rowData?.benefit?.pas?.socialRecord) {
      // setSocialRecord(rowData?.benefit?.pas?.socialRecord);
    }
  }, [rowData]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const editPas = () => {
    history.push(`/app/beneficios/pas/cidadao/${citizenId}/editar?benefitGrantedId=${benefitGrantedId}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function generateSegundaVia(data: ViaFormData) {
    const schema = Yup.object().shape({
      viaReason: Yup.string().required(messages.emptyField).typeError('Tipo inválido'),
      bankManager: Yup.string().required(messages.emptyField).typeError('Tipo inválido'),
    });
    try {
      viaFormRef.current?.setErrors({});
      await schema.validate(data, { abortEarly: false });
      const link = CitizenService.generateSegundaVia(
        Number(citizenId),
        data.viaReason,
        data.bankManager,
      );

      window.open(link, '_blank');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        viaFormRef.current?.setErrors(errorMessages);
      }
    }
  }

  const renderModalSegundaVia = () => (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Grid item md={4} sm={6}>
        <CardMain>
          <Form
            initialData={{
              bankManager: 'Leila Gondim Santos',
              viaReason: 'CARTÃO DANIFICADO',
            }}
            ref={viaFormRef}
            style={{ width: '100%' }}
            onSubmit={generateSegundaVia}
          >
            <Grid item xs={12}>
              <span>Preencha os dados abaixo para gerar a segunda via</span>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <MyTextInput
                  name="viaReason"
                  label="Motivo (obrigatório)"
                  InputLabelProps={inputLabelProps}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <MyTextInput
                  name="bankManager"
                  label="Gerente (obrigatório)"
                  InputLabelProps={inputLabelProps}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <ButtonMain type="submit" loading={false} title="Gerar" />
              </Grid>
            </Grid>
          </Form>
        </CardMain>
      </Grid>
    </Modal>
  );

  function generateFicha() {
    const link = CitizenService.generateFicha(Number(citizenId));
    window.open(link, '_blank');
  }
  function generateCommitmentTerm() {
    const link = CitizenService.generateCommitmentTerm(Number(citizenId));
    window.open(link, '_blank');
  }
  function generateCooperationTerm() {
    const link = CitizenService.generateCooperationTerm(Number(citizenId));
    window.open(link, '_blank');
  }
  function generateAccompanimentTerm() {
    const link = CitizenService.generateAccompanimentTerm(Number(citizenId));
    window.open(link, '_blank');
  }

  return (
    <div>
      <IconButton style={{ padding: 0 }} aria-label="Menu de ações" onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      // onClick={handleClose}
      >
        <MenuItem onClick={editPas}>Ver</MenuItem>
        <MenuItem onClick={openRecord}>Prontuário</MenuItem>
        <Accordion square className={classes.accordionDocuments}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            <Typography variant="body1">Documentos</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionSumDocuments}>
            <MenuList>
              <MenuItem onClick={() => setOpenModal(true)}>2° via</MenuItem>
              <MenuItem onClick={generateFicha}>Ficha cadastral</MenuItem>
              <MenuItem onClick={generateCommitmentTerm}>Termo de compromisso</MenuItem>
              <MenuItem onClick={generateCooperationTerm}>Termo de cooperação</MenuItem>
              <MenuItem onClick={generateAccompanimentTerm}>Termo de acompanhamento</MenuItem>
            </MenuList>
          </AccordionDetails>
        </Accordion>
      </Menu>

      {renderModalSegundaVia()}
    </div>
  );
}

export default withStyles(styles)(withSnackbar(BeneficiaryMenuPas));