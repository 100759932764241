import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import Benefits from '../../../components/pages/private/Benefits';
import PaoRoutes from './PaoNaMesa';

import PasRoutes from './Pas';
import PeixeRoutes from './Peixe';

const BeneficiosRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <Benefits />
      </Route>
      <Route path={`${path}/pas`}>
        <PasRoutes />
      </Route>
      <Route path={`${path}/peixe`}>
        <PeixeRoutes />
      </Route>
      <Route path={`${path}/paonamesa`}>
        <PaoRoutes />
      </Route>
    </Switch>
  );
}

export default BeneficiosRoutes;
