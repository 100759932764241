import React, { useRef, useEffect, ReactNode } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { useField } from '@unform/core';
import { TextField } from '@material-ui/core';

interface MyInputMaskProps extends InputProps {
  name: string;
  type?: string;
  mask: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  InputProps?: any;
  InputLabelProps?: any;
  children?: ReactNode;
  initialValue?: string | number;
}

export default function MyInputMask({ InputProps, InputLabelProps, required, label, name, type, mask, initialValue, disabled, ...rest }: MyInputMaskProps) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue = initialValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <ReactInputMask
      required={required}
      disabled={disabled}
      ref={inputRef}
      defaultValue={defaultValue}
      type={type}
      mask={mask}
      {...rest}
    >
      {
        () => (
          <TextField
            disabled={disabled}
            InputProps={InputProps}
            InputLabelProps={InputLabelProps}
            label={label}
            fullWidth
            helperText={error}
            error={!!error}
            type={type}
          />
        )
      }
    </ReactInputMask>
  );
};