import React from 'react';
import { withStyles, Grid } from '@material-ui/core';

import CardMain from '../../../../../templates/CardMain';

import styles from './styles';
import BarChart from '../../../../../templates/ChartBar';
import PieChart from '../../../../../templates/PieChart';
import { newPainelColors } from '../../../../../../constants/colors/painel.colors';

type Props = {
  classes: any;
  totalHomes: number;
  totalHomesImcomple: number;
  totalHomesByDistrict: { labels: string[], data: number[] };
  totalHomesByGarbageDisposal: { labels: string[], data: number[] };
  totalHomesByIncome: { labels: string[], data: number[] };
  totalHomesBySewage: { labels: string[], data: number[] };
  totalHomesByWaterSupply: { labels: string[], data: number[] };
  totalHomesByPowerSupply: any;
};

const HomeTab: React.FC<Props> = (props) => {
  const {
    classes,
    totalHomes,
    totalHomesByIncome,
    totalHomesBySewage,
    totalHomesImcomple,
    totalHomesByWaterSupply,
    totalHomesByPowerSupply,
    totalHomesByDistrict,
    totalHomesByGarbageDisposal,
  } = props;

  const renderPage = () => {
    return <>
      <Grid item xs={12} className={classes.mainItem}>
        <Grid container justify="center" >
          <Grid item xs={12} className={classes.cardItem}>
            <CardMain border="#177AFC7F">
              <Grid className={classes.flexRow}>
                <Grid item xs={8} className={classes.flexRow}>
                  <img alt="Domicílios Registrados" src="/assets/home_registered.png" className={classes.cardImg} />
                  <span className={classes.cardInfoTitle}>Total de Domicílios Cadastrados</span>
                </Grid>
                <Grid item xs={5} className={classes.flexColumn}>
                  <span className={classes.cardInfoTotal}>{totalHomes}</span>
                </Grid>
              </Grid>
            </CardMain>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} className={classes.cardItem}>
            <CardMain border="#177AFC7F">
              <Grid item xs={12} className={classes.flexRow}>
                <img alt="Domicílios Registrados" src="/assets/home_not_registered.png" className={classes.cardImg} />
                <span className={classes.cardInfoTitle}>Cadastros incompletos</span>
              </Grid>
              <Grid item xs={5} className={classes.flexColumn}>
                <span className={classes.cardInfoTotal}>{totalHomesImcomple}</span>
              </Grid>
            </CardMain>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <CardMain>
          <BarChart
            colors={newPainelColors}
            labels={totalHomesByDistrict.labels}
            data={totalHomesByDistrict.data}
            title="Cadastros por Bairro"
          />
        </CardMain>
      </Grid>
      <Grid item xs={6}>
        <CardMain>
          <BarChart
            colors={newPainelColors}
            labels={totalHomesByWaterSupply.labels}
            data={totalHomesByWaterSupply.data}
            title="Cadastros por Abastecimento de Água"
          />
        </CardMain>
      </Grid>
      <Grid item xs={6}>
        <CardMain>
          <BarChart
            colors={newPainelColors}
            labels={totalHomesByGarbageDisposal.labels}
            data={totalHomesByGarbageDisposal.data}
            title="Cadastros por Destino do Lixo"
          />
        </CardMain>
      </Grid>
      <Grid item xs={6}>
        <CardMain>
          <BarChart
            colors={newPainelColors}
            labels={totalHomesBySewage.labels}
            data={totalHomesBySewage.data}
            title="Cadastros por Escoamento do Banheiro"
          />
        </CardMain>
      </Grid>
      <Grid item xs={6}>
        <CardMain>
          <BarChart
            colors={newPainelColors}
            labels={totalHomesByIncome.labels}
            data={totalHomesByIncome.data}
            indexAxis="x"
            titleAlign="center"
            title="Cadastros por Renda"
          />
        </CardMain>
      </Grid>
      <Grid item xs={6}>
        <CardMain>
          <PieChart
            colors={newPainelColors}
            labels={totalHomesByPowerSupply.labels}
            data={totalHomesByPowerSupply.data}
            titleAlign="center"
            title="Cadastros por Fornecimento de Energia Elétrica"
          />
        </CardMain>
      </Grid>
    </>;
  }

  return (
    <Grid container spacing={4} className={classes.main}>
      {renderPage()}
    </Grid>
  );
}

export default withStyles(styles)(HomeTab);