import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  withStyles,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import MyTextInput from '../../../../../MyTextInput';

import styles from './styles';
import { messages } from '../../../../../../constants/messages';
import BenefitService from '../../../../../../services/BenefitService';
import { AuthContext } from '../../../../../../providers/Auth';
import { RecipientsBenefitsEntity } from '../../../../../../interfaces/entities/recipients-benefits.entity';

interface Props {
  classes: any;
  handleError(e: string): void
}

export interface RefProps {
  openDialog(benefitGrantedId: number, benefitiary: RecipientsBenefitsEntity): void;
}

interface FormData {
  record: string;
}

const inputLabelProps: any = { style: { color: '#707070', fontWeight: 'bold' } };

const SocialRecordDialog: React.ForwardRefRenderFunction<RefProps, Props> = (props, ref) => {
  const { classes, handleError } = props;
  const { accessToken } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [benefitiary, setBenefitiary] = useState<RecipientsBenefitsEntity>();
  const [benefitGrantedId, setBenefitGrantedId] = useState<number>();

  const formRef = useRef<FormHandles>(null);

  function openDialog(benefitGrantedId: number, benefitiary: RecipientsBenefitsEntity) {
    setBenefitiary(benefitiary);
    setBenefitGrantedId(benefitGrantedId);
    setOpen(true);
  }

  function closeDialog() {
    setOpen(false);
  }

  useImperativeHandle(ref, () => ({
    openDialog,
  }));

  async function onSubmit(data: FormData) {
    setIsLoading(true);
    const schema = Yup.object().shape({
      record: Yup.string().required(messages.emptyField).typeError('Tipo inválido'),
    });

    try {
      formRef.current?.setErrors({});
      await schema.validate(data, { abortEarly: false });
      if (benefitGrantedId) {
        await BenefitService.updateProntuario(accessToken, benefitGrantedId, data.record)
      }
      closeDialog();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        formRef.current?.setErrors(errorMessages);
      } else {
        handleError(error?.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth>
      <DialogTitle id="form-dialog-title">
        Prontuário social
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color="textPrimary">Nome completo</Typography>
            <Typography variant="subtitle2" color="textSecondary">{benefitiary?.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color="textPrimary">CPF</Typography>
            <Typography variant="subtitle2" color="textSecondary">{benefitiary?.cpf}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color="textPrimary">NIS</Typography>
            <Typography variant="subtitle2" color="textSecondary">{benefitiary?.nis}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color="textPrimary">NIB</Typography>
            <Typography variant="subtitle2" color="textSecondary">{benefitiary?.benefit?.nib || 'Sem registro'}</Typography>
          </Grid>
        </Grid>
        <Form
          ref={formRef}
          onSubmit={onSubmit}
          initialData={{
            record: benefitiary?.benefit?.social_record,
          }}
          className={classes.dialogContent}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <MyTextInput
                name="record"
                placeholder="Digite o prontuário social"
                label="Prontuário social"
                InputLabelProps={inputLabelProps}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" style={{ color: "#707070" }} />
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isLoading}
          onClick={() => formRef.current?.submitForm()}
          endIcon={isLoading ? <CircularProgress size={25} /> : null}
        >
          {!!benefitiary && !!benefitiary?.benefit?.social_record ? 'Editar' : 'Adicionar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(forwardRef(SocialRecordDialog));
