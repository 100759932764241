import React from 'react';
import { Button, CircularProgress, withStyles } from '@material-ui/core';

import styles from './styles';

type Props = { classes: any, onClick?: Function, loading: boolean, title: string, endIcon?: any, disabled?: boolean, type?: string };

const ButtonMain: React.FC<Props> = (props) => {
  const { classes, loading, title, onClick, disabled, type, endIcon } = props;
  return (
    <Button href='' className={classes.button} endIcon={endIcon} disabled={loading || disabled} type={type || 'button'} variant="contained" color="primary" onClick={onClick ? () => onClick() : () => { }}>
      {loading ? <CircularProgress size={25} /> : title}
    </Button >
  );
}

export default withStyles(styles)(ButtonMain);