/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { withStyles } from '@material-ui/core/styles';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

import MySelect from '../../../../MySelect';
import MyTextInput from '../../../../MyTextInput';
import withSnackbar from '../../../../templates/WithSnackbar';
import TableMain from '../../../../templates/TableMain';
import BeneficiaryMenuPas from '../components/BeneficiaryMenuPas';
import BenefitService from '../../../../../services/BenefitService';
import SocialRecordDialog, { RefProps as SocialRecordDialogRef } from '../components/SocialRecordDialog';

import styles from './styles';
import { AuthContext } from '../../../../../providers/Auth';
import registerStatusTypes from '../../../../../constants/registerStatusTypes';
import { RecipientsBenefitsEntity } from '../../../../../interfaces/entities/recipients-benefits.entity';
import { Benefits } from '../../../../../enums/Benefits';

type Props = {
  classes: any;
  openSnackbar: any;
  history: any;
  enableRegister: boolean;
};

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: string;
  format?: (value: number) => string;
  render?: (rowData: any) => any;
}

interface FormSearchData {
  term: string;
  status: number;
}

function BenefitListPas(props: Props) {
  const initialColumns: Column[] = [
    { id: 'name', label: 'Nome Completo', minWidth: 100, align: 'center' },
    { id: 'cpf', label: 'CPF', minWidth: 100, align: 'center' },
    { id: 'nis', label: 'NIS', minWidth: 100, align: 'center' },
    { id: 'benefit.nib', label: 'NIB', minWidth: 100, align: 'center', render: (rowData) => { return rowData.benefit.nib } },
    { id: 'telephone', label: 'Telefone', minWidth: 100, align: 'center' },
    {
      id: 'benefit.benefit_grant_status.description',
      label: 'Status',
      minWidth: 100,
      align: 'center',
      render: (rowData) => {
        return rowData.benefit.benefit_grant_status.description === 'Desligado' ? (
          <span style={{ color: '#95181C' }}>{rowData.benefit.benefit_grant_status.description}</span>
        ) : (
          rowData.benefit.benefit_grant_status.description
        );
      },
    },
    {
      id: 'actions',
      label: 'Ações',
      align: 'right',
      render: (rowData) => {
        return (
          <BeneficiaryMenuPas
            rowData={rowData}
            citizenId={rowData['id']}
            history={history}
            handleError={handleError}
            openSnackbar={openSnackbar}
            benefitGrantedId={rowData.benefit.id}
            openRecord={() => socialRecordDialogRef.current?.openDialog(rowData.benefit.id, rowData)}
          />
        );
      }
    }
  ];

  const reportColumns: Column[] = [
    { id: 'fullName', label: 'Nome Completo', minWidth: 100, align: 'center' },
    { id: 'age', label: 'Idade', minWidth: 100, align: 'center' },
    { id: 'cpf', label: 'CPF', minWidth: 100, align: 'center' },
    { id: 'nib', label: 'NIB', minWidth: 100, align: 'center' },
    { id: 'neighborhood', label: 'Bairro', minWidth: 100, align: 'center' },
    { id: 'isBpc', label: 'BPC', minWidth: 100, align: 'center' },
    { id: 'totalHouseIncome', label: 'Renda Bruta', minWidth: 100, align: 'center' },
    { id: 'membersNumber', label: 'Qtd', minWidth: 100, align: 'center' },
    { id: 'incomePerMember', label: 'Renda Per Capta', minWidth: 100, align: 'center', render: rowData => { return rowData['incomePerMember'] === undefined ? '0,00' : rowData['incomePerMember']; } },
    { id: 'benefitValue', label: 'Valor do Benefício', minWidth: 100, align: 'center' },
  ];

  const { classes, openSnackbar, history, enableRegister } = props;

  const [beneficiariesFiltered, setBeneficiariesFiltered] = useState<RecipientsBenefitsEntity[]>();
  const [columnsLoaded, setColumnsLoaded] = useState<boolean>(false);
  const [columns, setColumns] = useState<Column[]>(initialColumns);
  const [columnsReport, setColumnsReport] = useState<Column[]>(reportColumns);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { accessToken } = useContext(AuthContext);

  const searchFormRef = useRef<FormHandles>(null);
  const socialRecordDialogRef = useRef<SocialRecordDialogRef>(null);


  const hideLoading = () => {
    setIsLoading(false);
  };

  function handleError(e: any) {
    openSnackbar('error', e);
  };

  const openCreateForm = () => {
    history.push('/app/beneficios/pas/novo');
  };

  const searchBeneficiarios = async (data: FormSearchData) => {
    setIsLoading(true);
    const schema = Yup.object().shape({
      term: Yup.mixed().test({
        message: 'Este campo deve contér 5 digítos',
        test: (value) => {
          if (!!value && value.length < 5) {
            return false;
          }
          return true;
        }
      }),
      status: Yup.mixed(),
    }).test({
      message: 'Preencha pelo menos um campo',
      test: (value) => {
        if (
          !value.term &&
          !value.status
        ) {
          return false;
        }

        return true;
      }
    });
    try {
      searchFormRef.current?.setErrors({});
      await schema.validate(data, { abortEarly: false });

      const response = await BenefitService.searchBeneficiarios(
        Benefits.PAS,
        accessToken,
        data.term,
        data.status,
      );

      setBeneficiariesFiltered(response.results)
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};
        let emptyFieldsError = '';

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
          if (error.path === '') {
            emptyFieldsError = error.message;
          }
        });
        if (emptyFieldsError) {
          return openSnackbar('error', emptyFieldsError);
        }
        searchFormRef.current?.setErrors(errorMessages);
      } else {
        handleError(error?.message);
      }
    } finally {
      setIsLoading(false);
    }
    hideLoading();
  };


  const renderTableMain = () => {
    if (beneficiariesFiltered?.length === 0) {
      return (
        <div className={classes.divCenter}>
          {' '}
          <span>Não há beneficiários cadastrados ou tente novamente uma consulta.</span>
        </div>
      );
    };

    return <TableMain array={beneficiariesFiltered} columns={columns} />;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ margin: '30px 35px' }}>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <span className={classes.pageTitle}>PAS</span>
            <div className={classes.benefitTitleContainer}>
              <span className={classes.pageTitle}>Beneficiários</span>
              <div className={classes.pageLine}></div>
            </div>

            <Button
              color="primary"
              variant="contained"
              disabled={!enableRegister}
              onClick={openCreateForm}
              endIcon={<AddOutlinedIcon />}
            >
              Adicionar Beneficiário
            </Button>
          </Grid>
          <Form ref={searchFormRef} onSubmit={searchBeneficiarios}>
            <Grid container spacing={2} item className={classes.searchDiv}>
              <Grid item xs={6}>
                <MyTextInput
                  name="term"
                  variant="filled"
                  label="Busca rápida por nome, CPF, NIS, NIB, Prontuário Social..."
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 400, fontSize: 13 },
                  }}
                />
              </Grid>
              <Grid item xs>
                <MySelect
                  label="Selecione um Status"
                  name="status"
                  options={registerStatusTypes.filter((status) => status.benefits.indexOf(Benefits.PAS) !== -1).map((type) => ({ value: type.id, label: type.name }))}
                />
              </Grid>
              <Grid item style={{ display: 'flex' }}>
                <Button type="submit" color="primary" variant="contained">
                  Consultar
                </Button>
              </Grid>
            </Grid>
          </Form>
          {isLoading ? (
            <Grid container className={classes.gridCircularProgress}>
              <span style={{ marginRight: 15 }}>Aguarde um pouco...</span>
              <CircularProgress className={classes.circularProgress} />
            </Grid>
          ) : !beneficiariesFiltered ? (
            <div className={classes.divCenter}>
              <span style={{ marginTop: 15 }}>Realize uma consulta para ver os beneficiarios.</span>
            </div>
          ) : (
            renderTableMain()
          )}
        </Grid>
      </Grid>
      <SocialRecordDialog
        ref={socialRecordDialogRef}
        handleError={handleError}
      />
    </>
  );
}

export default withStyles(styles)(withSnackbar(BenefitListPas));
