/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { Grid, Button, Fade, Box, CircularProgress } from '@material-ui/core';

import BenefitCard from './components/BenefitCard';
import withSnackbar from '../../../templates/WithSnackbar';

import { AuthContext } from '../../../../providers/Auth';
import BenefitService from '../../../../services/BenefitService';

import styles from './styles';

type Props = {
  classes: any;
  login: Function;
  openSnackbar: any;
};

const Benefits: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;

  const { accessToken } = useContext(AuthContext);
  const [benefits, setBenefits] = useState<{ id: number; name: string }[]>();
  const [benefitManagement, setBenefitManagement] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getAllBenefits();
  }, []);

  const getAllBenefits = async () => {
    try {
      const response = await BenefitService.getBenefits(accessToken);
      setBenefits(response.results);
    } catch (error: any) {
      handleError(error.message);
    } finally {
      setLoading(false);
    };
  };

  function handleError(e: any) {
    openSnackbar('error', e);
  };

  const manageBenefits = () => {
    setBenefitManagement(true);
  };

  const saveChanges = async () => {
  };

  const revertChanges = () => {
  };

  if (!loading) {
    return (
      <Box className={classes.container}>
        <Grid container>
          <Grid item xs={12} style={{ margin: '30px 35px' }}>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <span className={classes.pageTitle}>Benefícios</span>
              <div className={classes.pageLine}></div>
              {false ? <Button className={classes.btnAdd} variant="outlined" onClick={manageBenefits} endIcon={<SettingsOutlinedIcon />}>
                Gerenciar Benefícios
              </Button> : null}
            </Grid>
          </Grid>
          <Grid container item className={classes.cardsContainer} xs={12} spacing={4}>
            {
              benefits?.map(benefit =>
                <BenefitCard
                  key={benefit.id}
                  benefit={benefit}
                  managing={benefitManagement}
                />)
            }
          </Grid>
        </Grid>
        <Fade in={benefitManagement}>
          <Grid container item md={12} className={classes.buttonContainer}>
            <Button variant="contained" className={classes.buttonCancel} onClick={revertChanges}>
              Cancelar
            </Button>
            <Button variant="contained" className={classes.buttonRegister} onClick={saveChanges}>
              Salvar
            </Button>
          </Grid>
        </Fade>
      </Box>
    );
  } else {
    return (
      <div className={classes.loading}>
        <CircularProgress size={50} />
      </div>
    );
  };

}

export default withStyles(styles)(withSnackbar(Benefits));
