/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useContext, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { InsertPhoto } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import Modal from '@material-ui/core/Modal';
import { FormHandles } from '@unform/core';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {
  Grid,
  Typography,
  Avatar,
  withStyles,
  Button,
  InputAdornment,
  FormControlLabel,
  CircularProgress,
  FormControl,
  FormGroup,
  FormLabel,
  Grow,
} from '@material-ui/core';

import withSnackbar from '../../../templates/WithSnackbar';

import { CitizenEntity } from '../../../../interfaces/entities/citizen.entity';
import { HomeEntity } from '../../../../interfaces/entities/home.entity';

import { messages } from '../../../../constants/messages';
import sexes from '../../../../constants/sexes';
import sexualOrientations from '../../../../constants/sexualOrientations';
import races from '../../../../constants/races';
import counties from '../../../../constants/counties';
import countries from '../../../../constants/countries';
import nationalities from '../../../../constants/nationalities';
import kinshipsResponsibleFamily from '../../../../constants/kinshipsResponsibleFamily';
import schoolings from '../../../../constants/schoolings';
import religions from '../../../../constants/religions';
import situationsJobMarket from '../../../../constants/situationsJobMarket';
import maritalStatusTypes from '../../../../constants/maritalStatusTypes';
import registrationsSituation from '../../../../constants/registrationsSituation';
import Neighborhood from '../../../../constants/neighborhoods';
import { default as deficienciesOptions } from '../../../../constants/deficiencies';

import styles from './styles';
import CitizenService from '../../../../services/CitizenService';
import StreetService from '../../../../services/StreetService';
import HomeService from '../../../../services/HomeService';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AuthContext } from '../../../../providers/Auth';
import Loading from '../../public/Loading';
import { CitizenFormEntity } from '../../../../interfaces/entities/citizen-form.entity';
import WebcamCapture from '../../../templates/WebcamCapture';
import MySelect from '../../../MySelect';
import MyTextInput from '../../../MyTextInput';
import MyCheckbox from '../../../MyCheckBox';
import MyInputMask from '../../../MyInputMask';
import CardMain from '../../../templates/CardMain';
import { StreetEntity } from '../../../../interfaces/entities/street.entity';
import { OmitedHomeAttributes } from '../../../../interfaces/responses/search-home.response';
import MyAutoComplete from '../../../MyAutocomplete';
import BenefitChangeDialog from './components/BenefitChangeDialog';
import { CitizenBenefitEntity } from '../../../../interfaces/entities/citizen-benefit.entity';
import { Benefits } from '../../../../enums/Benefits';

type Props = { classes: any; login: Function; openSnackbar: any; auth: any; history: any };

const CitizenRegister: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;
  const history = useHistory();
  const { cidadaoId } = useParams<{ cidadaoId: string | undefined }>();

  const [house, setHouse] = useState<HomeEntity>();
  const [citizen, setCitizen] = useState<CitizenEntity>();
  const [citizenBenefit, setCitizenBenefit] = useState<CitizenBenefitEntity | null>();
  const [isLoadingCitizen, setIsLoadingCitizen] = useState<boolean>(false);
  const [isLoadingHome, setisLoadingHome] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openCamera, setOpenCamera] = useState<boolean>(false);
  const [registrationSituation, setRegistrationSituation] = useState<string | undefined>('Ativo');
  const { accessToken, user } = useContext(AuthContext);
  const [streets, setStreets] = useState<StreetEntity[]>([]);
  const [birthDate, setBirthDate] = useState<Date | null>(citizen?.birthdate ? new Date(citizen?.birthdate) : null);
  const [isEmployee, setIsEmployee] = useState<boolean>();
  const [OpenSituation, setOpenSituation] = useState<boolean>(false);
  const [isResponsibleFamily] = useState<boolean | null>(false);
  const [updatedDate, setUpdatedDate] = useState<Date | null>(new Date());
  const [members] = useState<any[]>([]);
  const [cpf, setCpf] = useState<string>('');
  const [showBenefitChangeDialog, setBenefitChangeDialog] = useState(false);

  function useQueryParams() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const queryParams = useQueryParams();
  const [isRF, setIsRF] = useState<boolean>(false);
  const [isUnknowDad, setIsUnknowDad] = useState<boolean>(false);
  const [isUnknowMom, setIsUnknowMom] = useState<boolean>(false);
  const [nationality, setNationality] = useState<number>(0);
  const formRef = useRef<FormHandles>(null);

  // My states
  const [hasDeficiency, setHasDeficiency] = useState(false);
  const [birthCertificate, setBirthCertificate] = useState('');
  const [kinshipResponsible, setKinshipResponsible] = useState('');
  const [deficiency, setDeficiency] = useState<number[]>([]);
  const [isLoadingStreets, setIsLoadingStreets] = useState(false);
  const [isLoadingDomiciles, setIsLoadingDomiciles] = useState(false);
  const [domiciles, setDomiciles] = useState<Omit<HomeEntity, OmitedHomeAttributes>[]>([]);
  const [isMovingCitizen, setIsMovingCitizen] = useState(false);
  const [selectedDomicileId, setSelectedDomicileId] = useState<number>(0);
  const [naturalidade, setNaturalidade] = useState({ value: '', label: '' });

  function handleChangeJobSituation(value: number) {
    if (value === 1 || value === 2) {
      setIsEmployee(false);
    } else {
      setIsEmployee(true);
    }
  }

  useEffect(() => {
    setLoading(true);
    getCitizen();
    getHouse();
    setLoading(false);
  }, []);

  const loadIsResponsible = useCallback(() => {
    if (!!citizen && !!house) {
      setIsRF(citizen?.id === house.responsible_citizen_id);
    }
  }, [citizen, house]);

  useEffect(() => {
    loadIsResponsible();
  }, [loadIsResponsible]);

  const getCitizen = async () => {
    setIsLoadingCitizen(true);
    try {
      if (cidadaoId) {
        const result = await CitizenService.getCitizen(
          Number(cidadaoId),
          accessToken,
        );
        setCitizen(result);
        setCpf(result.cpf);
        setNationality(result.nationality_id);
        setBirthDate(new Date(result.birthdate));
        setNaturalidade({
          value: `${result?.birth_city}-${result?.birth_state}`,
          label: `${result?.birth_city}-${result?.birth_state}`,
        });
        setBirthCertificate(result.birth_certificate);
        setHasDeficiency(!!result?.deficiencies?.length);
        setDeficiency(result?.deficiencies?.map(state => state.id) || []);
        setKinshipResponsible(result?.responsible_kinship_id ? String(result?.responsible_kinship_id) : '');
      }
      setLoading(false);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoadingCitizen(false);
    };
  };

  async function getHouse() {
    setisLoadingHome(true);
    try {
      const houseId = queryParams.get('houseId') as string;
      const result = await HomeService.getHome(
        Number(houseId),
        accessToken,
      );
      if (result) {
        setHouse(result);
      }
    } catch (error: any) {
      handleError(error);
    } finally {
      setisLoadingHome(false);
    }
  }

  const goBack = async () => {
    history.goBack();
  };

  const saveCitizen = async (
    citizenFormData: CitizenFormEntity,
  ) => {
    try {
      if (cidadaoId) {
        const updateResponse = await CitizenService.updateCitizen(
          cidadaoId,
          accessToken,
          citizenFormData
        );

        let updateChangeBenefit = false;
        let moveCitizenChangeBenefit = false;

        if (updateResponse?.benefit_status !== undefined
          && updateResponse?.benefit_status !== null
          && updateResponse?.benefit_status?.id !== 1
        ) {
          setBenefitChangeDialog(true);
          updateChangeBenefit = true;
        }

        if (isMovingCitizen) {
          const movingResponse = await CitizenService.moveCitizenHome(
            accessToken,
            Number(cidadaoId),
            Number(selectedDomicileId)
          );

          if (movingResponse?.newHome.responsible_citizen_id != Number(cidadaoId)) {
            let benefit = movingResponse?.newHome?.responsible?.benefits ? movingResponse?.newHome.responsible.benefits.find(benefit => benefit.id === Benefits.BREAD) : null;

            if (benefit) {
              setCitizenBenefit(benefit.pivot);
              setBenefitChangeDialog(true);
            } else {
              setCitizenBenefit(null);
              setBenefitChangeDialog(true);
            }
          } else if (movingResponse?.benefit.benefit_grant_status !== undefined
            && movingResponse?.benefit.benefit_grant_status !== null
          ) {
            setCitizenBenefit(movingResponse.benefit);
            setBenefitChangeDialog(true);
          }
          moveCitizenChangeBenefit = true;
        }

        if (!updateChangeBenefit && !moveCitizenChangeBenefit) {
          const returnPath = queryParams.get('returnPath');
          if (returnPath) {
            history.replace(returnPath);
          }


          setTimeout(() => {
            history.goBack();
          }, 1500);
        }
        openSnackbar('success', 'Cidadão atualizado com sucesso');
      } else {
        await CitizenService.createCitizen(
          accessToken,
          citizenFormData
        );
        openSnackbar('success', 'Cidadão cadastrado com sucesso');
        const returnPath = queryParams.get('returnPath');
        if (returnPath) {
          history.replace(returnPath);
        }

        setTimeout(() => {
          history.goBack();
        }, 1500);
      }
    } catch (error: any) {
      throw error;
    }
  };

  function handleError(e: any) {
    openSnackbar('error', e);
  };

  const getStreets = async (district: string) => {
    setIsLoadingStreets(true);
    try {
      if (!!district) {
        const { results } = await StreetService.searchLogradouros(district, accessToken);
        setStreets(results);
      } else {
        setStreets([]);
        setDomiciles([]);
      }
    } catch (error: any) {
      handleError(error?.message);
    } finally {
      setIsLoadingStreets(false);
    };
  };

  const getDomiciles = async (district: string, street_name: string) => {
    setIsLoadingDomiciles(true);
    try {
      if (!!district && !!street_name) {
        const { results } = await HomeService.searchHomeByDistrictAndStreet(district, street_name, accessToken);
        setDomiciles(results);
      } else {
        setDomiciles([]);
      }
    } catch (error: any) {
      handleError(error?.message);
    } finally {
      setIsLoadingDomiciles(false);
    };
  };

  const onChangeImage = (e: any) => {
    const file = e.target.files[0] || null;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      // const result = e.target.result;
      // const base64result = result.split(',')[1];
      // setPhotoToShow(base64result);
      // setPhotoBlob(base64result);
    };

    reader.readAsDataURL(file);
  };

  const onRemovePhoto = () => {
    try {
      // setPhotoBlob(undefined);
      // setPhotoToShow(undefined);
    } catch (error: any) {
      handleError(error.message);
    };
  };

  const onGetImage = (src: any) => {
    // const base64result = src.split(',')[1];
    // setPhotoToShow(base64result);
    // setPhotoBlob(base64result);
    setOpenCamera(false);
  };

  async function handleSubmit(data: any) {
    setLoading(true);
    const fns = new DateFnsUtils();
    const countieInfo = data.birth_countie ? data.birth_countie.split('-') : null;
    const formData = {
      ...data,
      home_id: house?.id,
      responsible_kinship_id: data.kinshipResponsibleFamily || undefined,
      registration_status_id: data.registration_status_id,
      is_responsible: data.isResponsibleFamily,
      birth_state: countieInfo ? countieInfo[1] : undefined,
      birth_city: countieInfo ? countieInfo[0] : undefined,
      telephone: data.telephone.replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
      cpf: data.cpf.replace('.', '').replace('.', '').replace('-', ''),
      birthdate: !!birthDate ? fns.format(birthDate || new Date(), 'Y-MM-dd') : undefined,
      nis: data.nis || undefined,
      pis: data.pis || undefined,
      rg: data.rg.replace('.', '').replace('-', '') || undefined,
      deficiencies: deficiency,
    };

    const schema = Yup.object().shape({
      name: Yup.string().required(messages.emptyField),
      cpf: Yup.string().when('Age', {
        is: () => !birthCertificate || !data.birth_certificate,
        then: Yup.string().required(messages.emptyField)
      }),
      isResponsibleFamily: Yup.boolean().required(messages.emptyField).typeError('Tipo inválido'),
      kinshipResponsibleFamily: Yup.string().when('data.isResponsibleFamily', {
        is: () => !data.isResponsibleFamily || !isRF,
        then: Yup.string().required(messages.emptyField).typeError('Tipo inválido'),
        otherwise: Yup.string().typeError('Tipo inválido'),
      }),
      social_name: Yup.string(),
      birth_certificate: Yup.string().when('Age', {
        is: () => !cpf || !data.cpf,
        then: Yup.string().required(messages.emptyField)
      }),
      nis: Yup.string().nullable().when('isRF', {
        is: () => isRF || data.isResponsibleFamily,
        then: Yup.string().nullable().required(messages.emptyField).typeError('Tipo inválido'),
        otherwise: Yup.string().nullable().typeError('Tipo inválido'),
      }),
      pis: Yup.string().nullable(),
      rg: Yup.string().max(20, 'O RG deve contér até 20 números'),
      birthdate: Yup.string().nullable(),
      father_name: Yup.string().when('data.fatherNameIsUnknown', {
        is: () => !data.fatherNameIsUnknown,
        then: Yup.string().required(messages.emptyField)
      }),
      mother_name: Yup.string().when('data.motherNameIsUnknown', {
        is: () => !data.motherNameIsUnknown,
        then: Yup.string().required(messages.emptyField)
      }),
      bpc: Yup.boolean(),
      bolsa_aluguel: Yup.boolean(),
      bolsa_familia: Yup.boolean(),
      bolsa_universitaria: Yup.boolean(),
      transporte_universitario: Yup.boolean(),
      telephone: Yup.string().min(10, 'O número de contér mais de 10 numeros').max(11, 'O número de contér pelo menos 11 números'),
      birth_country: Yup.string().when('data.birth_country', {
        is: () => data.nationality_id === 3,
        then: () => Yup.string().required(messages.emptyFields),
      }),
      birth_countie: Yup.string().when('data.birth_countie', {
        is: () => data.nationality_id !== 3,
        then: () => Yup.string().required(messages.emptyFields),
      }),
      electoral_number: Yup.string(),
      electoral_zone: Yup.string(),
      electoral_section: Yup.string(),
      occupation: Yup.string(),
      study_interest: Yup.string(),
      current_study_subject: Yup.string(),
      currently_studying: Yup.string(),
      current_company: Yup.string(),
      complexion_id: Yup.number().required(messages.emptyField),
      schooling_id: Yup.number().required(messages.emptyField),
      gender_id: Yup.number().required(messages.emptyField),
      sexual_orientation_id: Yup.string().required(messages.emptyField),
      registration_status_id: Yup.string().required(messages.emptyField),
      marital_status_id: Yup.string().required(messages.emptyField),
      religion_id: Yup.string().required(messages.emptyField),
      job_situation_id: Yup.string().required(messages.emptyField),
      nationality_id: Yup.string(),
      hasDeficiency: Yup.boolean().required(messages.emptyField),
      deficiencias: Yup.boolean().nullable().test({
        message: 'Selecione pelo menos uma deficiência',
        test: () => !data.hasDeficiency || !!deficiency.length,
      })
    });
    try {
      formRef.current?.setErrors({});
      await schema.validate(formData, { abortEarly: false });
      await saveCitizen(formData as CitizenFormEntity);

    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        formRef.current?.setErrors(errorMessages);
        handleError('Preencha todos os campos obrigatórios');
      } else {
        handleError(error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  function handleSelectDeficiencies(event: any, deficiencyId: number) {
    const value: boolean = event.target.checked;
    if (value) {
      setDeficiency((prevState) => [...prevState, deficiencyId]);
    } else {
      const alreadyExists = deficiency.find(state => state === deficiencyId);
      if (alreadyExists) {
        setDeficiency((prevState) => {
          return prevState.filter((state) => state !== deficiencyId);
        })
      }
    }
  }

  return (
    <>
      <Grid item xs={12} style={{ margin: '30px 40px' }}>
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.pageTitle}>Cadastrar Novo Familiar</Typography>
          <div className={classes.pageLine}></div>
        </Grid>

        {!isLoadingCitizen && !isLoadingHome ? (

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              ...citizen,
              motherNameIsUnknown: (!citizen?.mother_name && !!citizen) ? !citizen?.mother_name : false,
              fatherNameIsUnknown: (!citizen?.father_name && !!citizen) ? !citizen?.father_name : false,
              electoral_section: citizen?.electoral_section,
              isResponsibleFamily: (house?.responsible_citizen_id === citizen?.id),
              kinshipResponsibleFamily: citizen?.responsible_kinship_id || undefined,
              hasDeficiency: !!citizen ? !!citizen?.deficiencies?.length : undefined,
              nomeOperador: user?.name,
              birth_country: citizen?.birth_country,
              registration_status_id: !citizen ? 1 : citizen.registration_status_id,
              birth_countie: naturalidade,
            }}>

            <Grid container spacing={4} item xs={12} className={classes.containerForm1}>
              {openCamera ? (
                <Grid item xs={12} className={classes.modalBg}>
                  <div className={classes.backgroundMdl}></div>
                  <WebcamCapture onGetImage={onGetImage} onClose={() => setOpenCamera(false)} />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <span className={classes.infoTitle}>Identificação do cidadão</span>
              </Grid>
              <Grid item xs={3} md={2} className={classes.avatarContainer}>
                <label htmlFor="avatar" className={classes.customLabel}>Foto</label>
                <Avatar id="avatar" variant="rounded" className={classes.avatarRounded}>
                  <InsertPhoto style={{ fontSize: 40 }} />
                </Avatar>
              </Grid>
              <Grid container item xs={3} md={2} alignContent='flex-start' style={{ display: 'flex', flexDirection: 'column' }}>
                <label htmlFor="upload-photo">
                  <input
                    style={{ display: "none" }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    onChange={(event: any) => onChangeImage(event)}
                  />
                  <Button className={classes.link} component="span">
                    Selecionar Foto
                  </Button>{" "}
                </label>
                <label>
                  <Button className={classes.link} component="span" onClick={() => setOpenCamera(true)}>
                    Tirar Foto com Câmera
                  </Button>{" "}
                </label>
                <Button className={classes.link} component="span" onClick={onRemovePhoto}>
                  Remover Foto
                </Button>{" "}
              </Grid>
              <Grid item xs={6} md={4}>
                <MySelect
                  onChange={(event) => {
                    setIsRF(event.target.value as boolean);
                    if (event.target.value) {
                      setKinshipResponsible('');
                    }
                  }}
                  label="Responsável familiar? (obrigatório)"
                  name="isResponsibleFamily"
                  options={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MySelect
                  label={`Grau de parentesco com o responsável ${isRF ? '' : '*'}`}
                  name="kinshipResponsibleFamily"
                  value={kinshipResponsible || undefined}
                  disabled={isRF}
                  onChange={(event) => setKinshipResponsible(event.target.value)}
                  options={kinshipsResponsibleFamily.map((type) => ({ value: type.id, label: type.name }))}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyTextInput
                  name="name"
                  label="Nome completo"
                  placeholder="Nome completo"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyTextInput
                  name="social_name"
                  label="Nome Social (Opcional)"
                  placeholder="Nome social"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                  <KeyboardDatePicker
                    className={classes.birthDatePicker}
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="data-nascimento"
                    label="Data de nascimento"
                    value={birthDate}
                    onChange={date => setBirthDate(date)}
                    invalidDateMessage="Formato de data inválido"
                    KeyboardButtonProps={{
                      'aria-label': 'mudar data',
                    }}
                    placeholder="Data de nascimento"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" style={{ color: "#707070" }} />
                      ),
                    }}
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6} md={4} style={{ marginBottom: 63 }}>
                <MySelect
                  label="Sexo"
                  name="gender_id"
                  options={sexes.map((type) => ({ value: type.id, label: type.name }))}
                />
              </Grid>
              <Grid container item xs={6} md={4} style={{}}>
                <MyTextInput
                  disabled={isUnknowMom ? true : false}
                  name="mother_name"
                  label="Nome da mãe (obrigatório)"
                  placeholder="Nome da mãe"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold', },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070", marginTop: 10 }} />
                    ),
                  }}
                />
                <FormControlLabel
                  style={{ marginTop: 10 }}
                  control={
                    <MyCheckbox
                      onChange={() => setIsUnknowMom(!isUnknowMom)}
                      name="motherNameIsUnknown"
                    />
                  }
                  label="Desconhecido"
                />
              </Grid>
              <Grid container item xs={6} md={4}>
                <MyTextInput
                  disabled={isUnknowDad ? true : false}
                  name="father_name"
                  label="Nome do pai (obrigatório)"
                  placeholder="Nome do pai"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
                <FormControlLabel
                  style={{ marginTop: 10 }}
                  control={
                    <MyCheckbox
                      name="fatherNameIsUnknown"
                      onChange={() => setIsUnknowDad(!isUnknowDad)}
                    />
                  }
                  label="Desconhecido"
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MySelect
                  onChange={(event) => {
                    setNationality(event.target.value);
                    if (event.target.value === 1) {
                      formRef!.current!.setFieldValue('birth_country', 'BRASIL');
                    } else {
                      formRef!.current!.setFieldValue('birth_countie', undefined);
                    }
                  }}
                  label="Nacionalidade (obrigatório)"
                  name="nationality_id"
                  options={nationalities.map((type) => ({ value: type.id, label: type.name }))}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MySelect
                  disabled={nationality === 1 ? true : false}
                  label="País de Nascimento (obrigatório)"
                  value={nationality === 1 ? 'BRASIL' : ''}
                  name="birth_country"
                  options={countries.map((country) => ({ value: country.name, label: country.name }))}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyAutoComplete
                  disabled={nationality !== 1 ? true : false}
                  label="Naturalidade (obrigatório)"
                  name="birth_countie"
                  value={naturalidade}
                  onChange={(event, newValue) => {
                    setNaturalidade(newValue);
                  }}
                  getOptionLabel={(option: any) => option.label}
                  options={counties.map((country) => ({
                    value: `${country.name}-${country.initials}`, label: `${country.name}-${country.initials}`
                  }))}
                />
              </Grid>

              <Grid item xs={12}>
                <span className={classes.infoTitle}>Informações Sociodemográficas</span>
              </Grid>
              <Grid item xs={6} md={4}>
                <MySelect
                  label="Estado Civil (obrigatório)"
                  name="marital_status_id"
                  options={maritalStatusTypes.map((type) => ({ value: type.id, label: type.name }))}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MySelect
                  label="Orientação sexual (obrigatório)"
                  name="sexual_orientation_id"
                  options={sexualOrientations.map((type) => ({ value: type.id, label: type.name }))}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MySelect
                  label="Raça/Cor (obrigatório)"
                  name="complexion_id"
                  options={races.map((type) => ({ value: type.id, label: type.name }))}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MySelect
                  label="Grau de escolaridade? (obrigatório)"
                  name="schooling_id"
                  options={schoolings.map((type) => ({ value: type.id, label: type.name }))}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyTextInput
                  name="current_study_subject"
                  label="Nome da Escola"
                  placeholder="Nome da Escola"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MySelect
                  label="Situação no Mercado de Trabalho (obrigatório)"
                  name="job_situation_id"
                  onChange={(event) => handleChangeJobSituation(event.target.value)}
                  options={situationsJobMarket.map((type) => ({ value: type.id, label: type.name }))}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyTextInput
                  disabled={!isEmployee}
                  name="current_company"
                  label="Nome da empresa que trabalha"
                  placeholder="Nome da empresa"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyTextInput
                  name="occupation"
                  label="Profissão"
                  placeholder="Profissão"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MySelect
                  label="Religião Praticada (obrigatório)"
                  name="religion_id"
                  options={religions.map((type) => ({ value: type.id, label: type.name }))}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MySelect
                  label="Portador de alguma deficiência? (obrigatório)"
                  name="hasDeficiency"
                  onChange={(event) => {
                    setHasDeficiency(event.target.value);
                    if (!event.target.value) {
                      setDeficiency([]);
                    }
                  }}
                  options={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
                />
              </Grid>
              <Grow in={hasDeficiency}>
                <FormControl error={!!formRef.current?.getFieldError('deficiencias')}>
                  <FormLabel style={{ fontWeight: 'bold', fontSize: 13 }}>
                    Quais deficiências * {formRef.current?.getFieldError('deficiencias') && `(${formRef.current?.getFieldError('deficiencias')})`}
                  </FormLabel>
                  <FormGroup row>
                    {deficienciesOptions.map((deficiencia, index) => (
                      <FormControlLabel
                        key={deficiencia.id}
                        style={{ marginTop: 10 }}
                        control={
                          <MyCheckbox
                            initialChecked={!!deficiency.find(state => state === deficiencia.id)}
                            onChange={(event) => handleSelectDeficiencies(event, deficiencia.id)}
                            name="deficiencias"
                          />
                        }
                        label={deficiencia.name}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grow>
              <Grid item xs={12}>
                <span className={classes.infoTitle}>Documentos Pessoais</span>
              </Grid>
              <Grid item xs={6} md={4}>
                <MyInputMask
                  mask="999.999.999-99"
                  type="cpf"
                  name="cpf"
                  onChange={(event) => setCpf(event.target.value)}
                  label={`CPF ${!!birthCertificate ? '' : '*'}`}
                  placeholder="000.000.000-00"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyTextInput
                  name="rg"
                  label="RG"
                  placeholder="RG"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyTextInput
                  required={isResponsibleFamily ? true : false}
                  name="nis"
                  label={`NIS ${isRF ? '*' : ''}`}
                  placeholder="NIS"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyTextInput
                  name="pis"
                  label="PIS"
                  placeholder="PIS"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyTextInput
                  name="birth_certificate"
                  label={`Certidão de Nascimento ${!!cpf ? '' : '*'}`}
                  placeholder="Certidão de Nascimento"
                  onChange={(event) => setBirthCertificate(event.target.value)}
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyTextInput
                  name="electoral_number"
                  label="Título"
                  placeholder="Digite o Título de Eleitor"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyTextInput
                  name="electoral_zone"
                  label="Zona"
                  placeholder="Digite a Zona Eleitoral"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <MyTextInput
                  name="electoral_section"
                  label="Seção"
                  placeholder="Digite a Seção Eleitoral"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <span className={classes.infoTitle}>Contatos</span>
              </Grid>
              <Grid item xs={6} md={4}>
                <MyInputMask
                  mask="(99) 99999-9999"
                  name="telephone"
                  required
                  label="Número do celular (obrigatório)"
                  placeholder="(99) 99999-9999"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
                <FormControlLabel
                  style={{ marginTop: 10 }}
                  control={
                    <MyCheckbox
                      name="only_message"
                    />
                  }
                  label="Somente recado"
                />
              </Grid>

              <Grid item xs={12}>
                <span className={classes.infoTitle}>Dados do cadastro</span>
              </Grid>
              <Grid item xs={6} md={4}>
                <MySelect
                  label="Situação do cadastro (obrigatório)"
                  onChange={event => {
                    const value: boolean = event.target.value === 4;
                    if (value) {
                      setOpenSituation(true)
                    }
                    if (!value) {
                      setRegistrationSituation(registrationsSituation.find(situation => situation.id === event.target.value)?.name);
                      setIsMovingCitizen(false);
                    }
                  }}
                  dismarkItem={false}
                  name="registration_status_id"
                  options={registrationsSituation.map((type) => ({ value: type.id, label: type.name }))}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <label htmlFor="data-ultima-atualizacao" className={classes.customLabel}>Data da Última Atualização</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                  <KeyboardDatePicker
                    required
                    className={classes.filledInput}
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="data-ultima-atualizacao"
                    value={updatedDate?.toDateString()}
                    onChange={date => setUpdatedDate(date)}
                    invalidDateMessage="Formato de data inválido"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    inputProps={{
                      style: { padding: '15px 12px 10px' },
                    }}
                    inputVariant='filled'
                    placeholder="DD/MM/AAAA"
                    disabled
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6} md={4}>
                <label htmlFor="nome-operador" className={classes.customLabel}>Operador do cadastro</label>
                <MyTextInput
                  required
                  name="nomeOperador"
                  placeholder="Nome do operador"
                  disabled
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              {(registrationSituation === 'Óbito' || registrationSituation === 'Mudança de Território') && isRF &&
                <Grid className={classes.alertRegistration} style={{ width: '100%', justifyContent: 'center' }}>
                  <WarningIcon />
                  <span style={{ marginLeft: 15 }}>O benefício do indivíduo será DESLIGADO</span>
                </Grid>}
              <Grid container item spacing={4}>
                <Modal
                  open={OpenSituation}
                  onClose={() => setOpenSituation(false)}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Grid item xs={8}>
                    <CardMain>
                      <Grid item xs={12} >
                        {isResponsibleFamily &&
                          <Grid className={classes.alertRegistration}>
                            <WarningIcon />
                            <span style={{ marginLeft: 15 }}>O novo responsável pelo domicílio atual será {members[0]?.fullName} e o benefício do indivíduo será BLOQUEADO</span>
                          </Grid>}
                        <span>Informe o novo destino:</span>
                        <MySelect
                          label="Bairro"
                          onChange={(event) => getStreets(event.target.value)}
                          name="district_change"
                          options={Neighborhood.map((type) => ({ value: type.name, label: type.name }))}
                        />
                        <MySelect
                          label="Logradouro"
                          onChange={async (event) => {
                            const district: string = formRef.current?.getFieldValue('district_change');
                            getDomiciles(district, event.target.value);
                          }}
                          name="street_change"
                          disabled={isLoadingStreets}
                          options={streets.map((type) => ({
                            value: type.name,
                            label: `${type.street_type.description} - ${type.name}`,
                          }))}
                        />
                        <MySelect
                          label="Domicílios"
                          name="domicile_change"
                          disabled={isLoadingDomiciles}
                          onChange={(event) => setSelectedDomicileId(event.target.value)}
                          options={domiciles.filter(domicile => domicile.id != house?.id).map((type) => ({
                            value: type.id,
                            label: `${type?.street_number ? 'N° ' + type.street_number : 'Sem número'} - ${type.complement || 'Sem complemento'}`
                          }))}
                        />
                        <Grid item xs={6} md={6} className={classes.divModal}>
                          <Button disabled={loading} variant="contained" className={classes.buttonCancel1} onClick={() => { setOpenSituation(false) }} >
                            Cancelar
                          </Button>
                          <Button
                            disabled={loading}
                            variant="contained"
                            className={classes.buttonUpdater}
                            onClick={() => {
                              setIsMovingCitizen(true);
                              setOpenSituation(false);
                              setRegistrationSituation('Ativo');
                            }}
                          >
                            Confimar seleção
                          </Button>
                        </Grid>
                      </Grid>
                    </CardMain>
                  </Grid>
                </Modal>
              </Grid>
              <Grid container item spacing={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={6} md={4} className={classes.divCenter}>
                  <Button disabled={loading} variant="contained" className={classes.buttonCancel1} onClick={() => goBack()}>
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    disabled={loading}
                    variant="contained"
                    className={classes.buttonRegister1}
                  >
                    {loading ? <CircularProgress size={25} /> : (citizen ? "Editar" : "Cadastrar")}
                  </Button>

                </Grid>
              </Grid>
            </Grid>
          </Form>
        ) : <Loading />}
      </Grid>
      <BenefitChangeDialog
        benefitId={Number(citizenBenefit?.benefit_id)}
        benefitGrantedId={Number(citizenBenefit?.id)}
        cidadaoId={Number(citizenBenefit?.citizen_id)}
        updateChangeBenefit={(registrationSituation === 'Óbito' || registrationSituation === 'Mudança de Território')}
        showDialog={showBenefitChangeDialog}
        toggleDialog={() => setBenefitChangeDialog(prevState => !prevState)}
      />
    </>
  );
}

export default withStyles(styles)(withSnackbar(CitizenRegister));
