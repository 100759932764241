import { useState, useRef, useContext, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Box, Button, CircularProgress, Divider, FormControlLabel, FormGroup, Grid, InputAdornment, Typography, withStyles } from '@material-ui/core';

import withSnackbar from '../../../templates/WithSnackbar';

import styles from './styles';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../public/Loading';
import { AuthContext } from '../../../../providers/Auth';
import DeliveryPostService from '../../../../services/DeliveryPostService';
import UserService from '../../../../services/UserService';
import MyCheckbox from '../../../MyCheckBox';
import { UserEntity } from '../../../../interfaces/entities/user.entity';
import { DeliveryPostEntity } from '../../../../interfaces/entities/delivery-post.entity';

type Props = { classes: any, openSnackbar: any, history: any };

function ManageUserPosts(props: Props) {
  const { classes, openSnackbar } = props;

  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { accessToken } = useContext(AuthContext);
  const { userId } = useParams<{ userId: string | undefined }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [posts, setPosts] = useState<DeliveryPostEntity[]>([]);
  const [user, setUser] = useState<UserEntity>();
  const [userPosts, setUserPosts] = useState<number[]>([]);

  useEffect(() => {
    setIsLoading(true);
    if (userId) {
      getUser();
      getUserPosts();
      getDeliveryPosts();
      setIsLoading(false);
    }
  }, []);

  const getDeliveryPosts = async () => {
    try {
      const response = await DeliveryPostService.getAllPosts(accessToken);
      setPosts(response.results);

    } catch (error: any) {
      openSnackbar('error', error.message);
    }
  }

  const getUserPosts = async () => {
    try {
      const response = await UserService.getUserPosts(Number(userId), accessToken);
      setUserPosts(response.results.map(post => post.id));
    } catch (error: any) {
      openSnackbar('error', error.message);
    }
  }

  async function getUser() {
    setIsLoading(true);
    try {
      let user = await UserService.get(accessToken, Number(userId));

      setUser(user);
    } catch (error: any) {
      openSnackbar('error', error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSyncPosts() {
    setIsLoadingSubmit(true);

    try {
      await UserService.syncPosts(Number(userId), userPosts, accessToken);

      openSnackbar('success', 'Posto(s) de Entrega atribuido(s) com sucesso');

      setTimeout(() => history.goBack(), 1400);
    } catch (error: any) {

      openSnackbar('error', error.message);
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  function handleSelectPosts(event: any, postId: number) {
    const value: boolean = event.target.checked;
    if (value) {
      setUserPosts((prevState) => [...prevState, postId]);
    } else {
      const alreadyExists = userPosts.find(state => state === postId);
      if (alreadyExists) {
        setUserPosts((prevState) => {
          return prevState.filter((state) => state !== postId);
        })
      }
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ margin: '30px 40px' }}>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <span className={classes.pageTitle}>Gerenciar Postos de Entrega</span>
            <div className={classes.pageLine}></div>
          </Grid>

          {!isLoading ?
            (
              <Form ref={formRef} onSubmit={handleSyncPosts}>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: 40 }}>
                  <Grid container spacing={4} className={classes.containerForm}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" color="textPrimary">Nome</Typography>
                      <Typography variant="subtitle2" color="textSecondary">{user?.name}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" color="textPrimary">E-mail</Typography>
                      <Typography variant="subtitle2" color="textSecondary">{user?.email}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" color="textPrimary">Login</Typography>
                      <Typography variant="subtitle2" color="textSecondary">{user?.login}</Typography>
                    </Grid>

                    <Divider className={classes.dividerValidate} />

                    <Grid item xs={12}>
                      <Typography variant="subtitle1" color="textPrimary">Postos de Entrega</Typography>
                    </Grid>
                    <Grid container direction='row' xs={12}>
                      <FormGroup>
                        {posts.map((post) => (
                          <FormControlLabel
                            key={post.id}
                            style={{ marginLeft: 10 }}
                            control={
                              <MyCheckbox
                                initialChecked={!!userPosts.find(state => state === post.id)}
                                onChange={(event) => handleSelectPosts(event, post.id)}
                                name="Postos de Entrega"
                              />
                            }
                            label={`${post.description} - ${Number(post.assigned_users)} Usuários atribuidos`}
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end" gridGap={8}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => history.goBack()}
                          disabled={isLoadingSubmit}
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isLoadingSubmit}
                        >
                          {
                            isLoadingSubmit ? (
                              <CircularProgress size={20} />
                            ) : 'Confirmar'
                          }
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )
            : <Loading />}
        </Grid>
      </Grid>
    </>

  );
}

export default withStyles(styles)(withSnackbar(ManageUserPosts));