import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
} from 'react-router-dom';

import PublicRoutes from '../Public';
import PrivateRoutes from '../Private';
import PeixeRoutes from '../Peixe';

const MainRoute: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/public" />
        </Route>
        <Route path="/public">
          <PublicRoutes />
        </Route>
        <Route path="/app">
          <PrivateRoutes />
        </Route>
        {/*<Route path="/peixe">
          <PeixeRoutes />
        </Route>*/}
      </Switch>
    </BrowserRouter>
  );
}

export default MainRoute;
