import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import BenefitList from '../../../../components/pages/private/BenefitList';
import PasGrantBenefit from '../../../../components/pages/private/PasGrantBenefit';
import PasGrantSearch from '../../../../components/pages/private/PasGrantSearch';

const PasRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <BenefitList benefit={1} />
      </Route>
      <Route path={`${path}/novo`}>
        <PasGrantSearch />
      </Route>
      <Route path={`${path}/cidadao/:citizenId/conceder`}>
        <PasGrantBenefit />
      </Route>
      <Route path={`${path}/cidadao/:citizenId/editar`}>
        <PasGrantBenefit />
      </Route>
    </Switch>
  );
}

export default PasRoutes;
