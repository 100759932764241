const styles: any = {
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
  },
  filterDiv: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #0A0A0A59',
    borderRadius: 10,
    padding: 20,
    fontSize: 14,
    flex: 1,
    margin: 'auto',
    width: "100%",
  },
  formControl: {
    margin: 'auto',
    width: "100%",
  },
  cardImg: {
    maxWidth: '200px',
    margin: 'auto',
    marginBottom: 30,

  },
  noResultsSpan: {
    color: '#0A4F9D',
    fontSize: 19,
    textAlign: 'center',
  },
  image: {
    maxWidth: '256px',
    marginBottom: 30,
    padding: '50px',
  },
  divCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  divCenterText: {
    height: '100%',
  },
  divFlex1: {
    height: '95%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  resultDiv: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #0A0A0A59',
    borderRadius: 10,
    padding: 10,
    fontSize: 14,
    flex: 1,
    margin: 'auto',
    width: "100%",
  },
  filterTitle: {
    color: '#0A0A0A59',
    fontWeight: 'bold',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  titleResults: {
    padding: 5,
    fontSize: 18,
  },
  buttonRegister: {
    display: 'flex',
    color: '#fff',
    textTransform: 'capitalize',
  },
  btnFilter: {
    color: '#177AFC',
    border: '1px solid #177AFC',
    margin: '0 0 0 10px',
    padding: '10px 25px',
    fontWeight: 'bold',
    '&:disabled': {
      border: '1px solid #CCCCCC',
    }
  },
  btnBlue: {
    backgroundColor: '#0A4F9D',
    color: '#FFFFFF',
    marginLeft: '10px',
    textTransform: 'capitalize',
    fontSize: 14,
    padding: '5px 20px 5px 20px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridContainerCardMain: {
    height: '90vh',
    overflow: 'auto',
    justifyContent: 'flex-end',
  },
  tableReport: {
    // height: '25vh',
  },
  tableToolBar: {
    width: '100%',
    marginBottom: '0.5rem',
  },
};

export default styles;