const styles: any = {
    containerForm: {
        padding: 50,
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center',
    },
    subTitle: {
        fontSize: "14px",
        fontWeight: 300,
        fontFamily: 'Roboto',
    },
    button: {
        fontSize: '14px',
        fontWeight: '400',
    },
    buttonClean: {
        borderColor: '#878787',
        color: '#878787',
        "&:hover": {
            backgroundColor: '#878787',
            color: '#fff',
        },
    },
    buttonCancel: {
        borderColor: '#D4586D',
        color: '#D4586D',
    },
    buttonRegister: {
        marginLeft: '15px',
        backgroundColor: '#004692',
        color: '#fff',
        "&:hover": {
            backgroundColor: '#408BCC'
        }
    },
    select: {
        width: '100%',
    },
    divCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    pageTitle: {
        color: '#0A0A0A59',
        fontWeight: 'bold',
        marginRight: '15px',
    },
    pageLine: {
        backgroundColor: '#0A0A0A59',
        flex: 1,
        height: 1,
        marginRight: '15px',
    },
    btnFilter: {
        margin: '0 10px',
    },
    btnAdd: {
        color: '#FFFFFF',
        margin: '0 10px',
    },
    popper: {
        position: 'absolute !important',
        left: '10px !important',
        zIndex: 3,
        top: 'unset !important',
    },
    buttonConsult: {
        marginLeft: '15px',
        color: '#fff',
        display: 'flex',
        alignSelf: 'flex-end',
    },
};

export default styles;