import React, {
  useRef,
  useState,
  useContext,
} from 'react';
import {
  Grid,
  Button,
  Tooltip,
  Typography,
  withStyles,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

import { messages } from '../../../../constants/messages';
import withSnackbar from '../../../templates/WithSnackbar';

import Column from '../../../../model/entities/Column';

import styles from './styles';
import { AuthContext } from '../../../../providers/Auth';
import BenefitService from '../../../../services/BenefitService';
import MyTextInput from '../../../MyTextInput';
import TableMain from '../../../templates/TableMain';
import { CandidatesBenefitsEntity } from '../../../../interfaces/entities/candidates-benefits.entity';
import withConfirmDialog, { WrappedType } from '../../../templates/withConfirmDialog';

interface Props extends WrappedType {
  classes: any,
  login: Function,
  openSnackbar: any,
};

interface FormSearchData {
  term: string;
}

const PasGrantSearch: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;
  const { accessToken } = useContext(AuthContext);

  const [candidatesBenefits, setCandidatesBenefits] = useState<CandidatesBenefitsEntity[]>([]);
  const [isLoadingCandidates, setIsLoadingCandidates] = useState(false);

  const citizenSelectionFormRef = useRef<FormHandles>(null);

  const history = useHistory();

  const candidatesColumns: Column[] = [
    { id: 'name', label: 'Nome Completo', minWidth: 100, align: 'center' },
    { id: 'cpf', label: 'CPF', minWidth: 100, align: 'center' },
    { id: 'nis', label: 'NIS', minWidth: 100, align: 'center' },
    { id: 'nib', label: 'NIB', minWidth: 100, align: 'center' },
    { id: 'telephone', label: 'Telefone', minWidth: 100, align: 'center' },
  ];

  const searchCitizen = async (data: FormSearchData) => {
    setIsLoadingCandidates(true);

    const schema = Yup.object().shape({
      term: Yup.string().required(messages.emptyField),
    });

    try {
      await schema.validate(data, { abortEarly: false });

      const response = await BenefitService.searchCandidatesBenefits(
        Number(1),
        accessToken,
        data.term,
      );

      setCandidatesBenefits(response.results);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });

        citizenSelectionFormRef.current?.setErrors(errorMessages);
      } else {
        handleError(error?.message);
      }
    } finally {
      setIsLoadingCandidates(false);
    }
  };

  async function handleCandidateClick(candidate: CandidatesBenefitsEntity) {
    history.push(`/app/beneficios/pas/cidadao/${candidate.id}/conceder`);
  }

  function handleError(e: any) {
    openSnackbar('error', e);
  };

  return (
    <Grid container style={{ margin: '30px 0px 30px 40px', width: 'calc(100% - 112px)' }}>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title="Voltar para página anterior">
          <IconButton onClick={() => history.goBack()}>
            <ArrowBack />
          </IconButton>
        </Tooltip>
        <span className={classes.pageTitle}>Adicionar Beneficiário</span>
        <div className={classes.pageLine}></div>
      </Grid>

      <Grid item xs={12} style={{ marginTop: 40 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <Form ref={citizenSelectionFormRef} onSubmit={searchCitizen}>
              <Grid container spacing={4} className={classes.containerForm}>
                <Grid item xs={8}>
                  <MyTextInput
                    name="term"
                    label="Busca rápida por nome, CPF, NIS, NIB..."
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4} className={classes.divCenter}>
                  <Button type="submit"color="primary" variant="contained" className={classes.buttonRegister}>
                    Pesquisar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Grid>
          {
            isLoadingCandidates && (
              <Grid item xs={12} className={classes.gridCircularProgress}>
                <Typography variant="subtitle1" style={{ marginRight: 15 }}>Carregando...</Typography>
                <CircularProgress className={classes.circularProgress} />
              </Grid>
            )
          }
          {
            candidatesBenefits?.length === 0
              ? (
                <Grid item xs={12} className={classes.divCenter}>
                  {' '}
                  <Typography variant="subtitle1">
                    Não foram encontrados cidadãos com essa informação, tente novamente outra consulta.
                  </Typography>
                </Grid>
              )
              : (
                <Grid item xs={12}>
                  <TableMain
                    array={candidatesBenefits}
                    columns={candidatesColumns}
                  />
                </Grid>
              )
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(withSnackbar(
  withConfirmDialog(PasGrantSearch),
));
