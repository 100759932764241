import React, {
  useRef,
  useState,
  useContext,
} from 'react';
import {
  Grid,
  Button,
  Tooltip,
  Typography,
  withStyles,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

import { messages } from '../../../../constants/messages';
import withSnackbar from '../../../templates/WithSnackbar';

import Column from '../../../../model/entities/Column';

import styles from './styles';
import { AuthContext } from '../../../../providers/Auth';
import BenefitService from '../../../../services/BenefitService';
import MyTextInput from '../../../MyTextInput';
import TableMain from '../../../templates/TableMain';
import { CandidatesBenefitsEntity } from '../../../../interfaces/entities/candidates-benefits.entity';
import withConfirmDialog, { WrappedType } from '../../../templates/withConfirmDialog';
import BenefitFishService from '../../../../services/BenefitFishService';

interface Props extends WrappedType {
  classes: any,
  login: Function,
  openSnackbar: any,
};

interface FormSearchData {
  term: string;
}

const PasGrantSearch: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;
  const { accessToken } = useContext(AuthContext);

  const [candidatesBenefits, setCandidatesBenefits] = useState<CandidatesBenefitsEntity[]>([]);
  const [isLoadingCandidates, setIsLoadingCandidates] = useState(false);
  const [showConcessionDialog, setShowConcessionDialog] = useState(false);

  const concessionButtonRef = useRef<HTMLButtonElement | null>(null);
  const concessionCitizenRef = useRef<number | null>(null);

  const citizenSelectionFormRef = useRef<FormHandles>(null);

  const history = useHistory();

  const candidatesColumns: Column[] = [
    { id: 'name', label: 'Nome Completo', minWidth: 100, align: 'center' },
    { id: 'cpf', label: 'CPF', minWidth: 100, align: 'center' },
    { id: 'nis', label: 'NIS', minWidth: 100, align: 'center' },
    { id: 'telephone', label: 'Telefone', minWidth: 100, align: 'center' },
    {
      id: 'id',
      label: '',
      minWidth: 100,
      align: 'center',
      render: (rowData: CandidatesBenefitsEntity) => {
        return (
          <Button
            type="button"
            className={classes.buttonSelection}
            variant="contained"
            onClick={(e) => handleCandidateClick(e, rowData.id)}
          >
            Conceder Benefício
          </Button>
        );
      },
    },
  ];

  const searchCitizen = async (data: FormSearchData) => {
    setIsLoadingCandidates(true);

    const schema = Yup.object().shape({
      term: Yup.string().required(messages.emptyField),
    });

    try {
      await schema.validate(data, { abortEarly: false });

      const response = await BenefitService.searchCandidatesBenefits(
        Number(2),
        accessToken,
        data.term
      );

      setCandidatesBenefits(response.results);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });

        citizenSelectionFormRef.current?.setErrors(errorMessages);
      } else {
        handleError(error?.message);
      }
    } finally {
      setIsLoadingCandidates(false);
    }
  };

  function handleCandidateClick(event: React.MouseEvent<HTMLButtonElement>, candidateId: number) {
    concessionButtonRef.current = event.currentTarget;
    concessionCitizenRef.current = candidateId;

    setShowConcessionDialog(true);
  }

  function handleConcessionDialogClose() {
    setShowConcessionDialog(false);
  }

  function handleCancelConcessionClick() {
    setShowConcessionDialog(false);
  }

  async function handleConfirmConcessionClick() {
    const myButton = concessionButtonRef.current;
    const myCitizen = concessionCitizenRef.current;

    if (myCitizen) {
      if (myButton) {
        myButton.disabled = true;
        myButton.innerText = 'Aguarde, concedendo benefício...';
      }
      
      setShowConcessionDialog(false);
      
      try {
        await BenefitFishService.grant(myCitizen, new Date().getTime().toString(), accessToken);

        if (myButton) {
          myButton.replaceWith('benefício concedido');
        }
      } catch (error: any) {
        handleError(error.message);

        if (myButton) {
          myButton.disabled = false;
          myButton.innerText = 'Conceder Benefício'
        }
      }
    } else {
      handleError('Encontramos um problema ao solicitar a concessão, tente novamente em instantes.');
    }

    concessionButtonRef.current = concessionCitizenRef.current = null;
  }

  function handleError(e: any) {
    openSnackbar('error', e);
  };

  return (
    <Grid container style={{ margin: '30px 0px 30px 40px', width: 'calc(100% - 112px)' }}>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title="Voltar para página anterior">
          <IconButton onClick={() => history.goBack()}>
            <ArrowBack />
          </IconButton>
        </Tooltip>
        <span className={classes.pageTitle}>Adicionar Beneficiário</span>
        <div className={classes.pageLine}></div>
      </Grid>

      <Grid item xs={12} style={{ marginTop: 40 }}>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <Form ref={citizenSelectionFormRef} onSubmit={searchCitizen}>
              <Grid container spacing={4} className={classes.containerForm}>
                <Grid item xs={8}>
                  <MyTextInput
                    name="term"
                    label="Busca rápida por nome, CPF, NIS e NIB *"
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4} className={classes.divCenter}>
                  <Button type="submit"color="primary" variant="contained" className={classes.buttonRegister}>
                    Pesquisar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Grid>
          {
            isLoadingCandidates && (
              <Grid item xs={12} className={classes.gridCircularProgress}>
                <Typography variant="subtitle1" style={{ marginRight: 15 }}>Carregando...</Typography>
                <CircularProgress className={classes.circularProgress} />
              </Grid>
            )
          }
          {
            candidatesBenefits?.length === 0
              ? (
                <Grid item xs={12} className={classes.divCenter}>
                  {' '}
                  <Typography variant="subtitle1">
                    Não foram encontrados cidadãos com essa informação, tente novamente outra consulta.
                  </Typography>
                </Grid>
              )
              : (
                <Grid item xs={12}>
                  <TableMain
                    array={candidatesBenefits}
                    columns={candidatesColumns}
                  />
                </Grid>
              )
          }
        </Grid>
      </Grid>
      <Dialog onClose={handleConcessionDialogClose} open={showConcessionDialog}>
        <DialogTitle id="concession-dialog-title">Conceder Vale da Semana Santa</DialogTitle>
        <DialogContent>
          <DialogContentText id="concession-dialog-description">
            Esta ação permitirá que o cidadão acesse o formulário de confirmação de dados e gere o QR Code 
            de recebimento do Vale da Semana Santa. Deseja continuar?
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleCancelConcessionClick} color="secondary" variant="text">
              não
            </Button>
            <Button onClick={handleConfirmConcessionClick} color="primary" autoFocus>
              SIM
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default withStyles(styles)(withSnackbar(
  withConfirmDialog(PasGrantSearch),
));
